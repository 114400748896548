import {
  CommonIncidentId,
  EventStatusId,
  IncidentAttributeId,
  IncidentId,
  Sport,
  SportId,
} from '@collector/sportsapi-client-legacy'

import { AmericanFootballConfiguration } from './AmericanFootball/AmericanFootballConfiguration'
import { BasketballConfiguration } from './Basketball/BasketballConfiguration'
import {
  SportAttributesConfiguration,
  SportDetailsConfiguration,
} from './composables/useSportOptions/types'
import { IceHockeyConfiguration } from './IceHockey/IceHockeyConfiguration'
import { SoccerConfiguration } from './Soccer/SoccerConfiguration'

export type DetailsPanelSportConfiguration<
  TIncidentId extends IncidentId,
  TIncidentAttributeId extends IncidentAttributeId,
  TEventStatusId extends EventStatusId,
> = {
  groupNames: object
  detailsConfiguration: SportDetailsConfiguration<
    TIncidentId,
    TIncidentAttributeId
  >
  attributesConfiguration: SportAttributesConfiguration<
    TIncidentId,
    TIncidentAttributeId
  >
  filters: {
    hideIncidents: (TIncidentId | CommonIncidentId)[]
    participantIncidents: TIncidentId[]
    detailsIncidents: TIncidentId[]
    selectedOnStartDetailsIncidents?: TIncidentId[]
  }
  secondParticipantIncidents: TIncidentId[]
  manualAddIncidentModal: {
    statuses: TEventStatusId[]
    incidents: TIncidentId[]
    substitutionIncidents?: TIncidentId[]
  }
}

export function getDetailsPanelSportConfiguration<
  T extends IncidentId,
  K extends IncidentAttributeId,
  S extends EventStatusId,
>(sportId: Sport['id']): DetailsPanelSportConfiguration<T, K, S> {
  switch (sportId) {
    case SportId.Soccer:
      return SoccerConfiguration as DetailsPanelSportConfiguration<T, K, S>
    case SportId.Basketball:
      return BasketballConfiguration as DetailsPanelSportConfiguration<T, K, S>
    case SportId.IceHockey:
      return IceHockeyConfiguration as DetailsPanelSportConfiguration<T, K, S>
    case SportId.AmericanFootball:
      return AmericanFootballConfiguration as DetailsPanelSportConfiguration<
        T,
        K,
        S
      >
    default:
      throw new Error('Sport is not supported in details panel')
  }
}
