<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  Ref,
  ref,
} from 'vue'

import { nullCoalesce, opChain } from '@collector/shared-utils'
import {
  EventScoutRank,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import ProtocolHeaderButton from '@mobile/components/HeaderButton/ProtocolHeaderButton.vue'
import TrackerHeaderButton from '@mobile/components/HeaderButton/TrackerHeaderButton.vue'
import Select from '@mobile/components/Select/Select.vue'
import ToggleSwitch from '@mobile/components/ToggleSwitch/ToggleSwitch.vue'
import { getTeamSide } from '@mobile/reusables/entityUtils'
import IncidentDeleteModal from '@mobile/views/Relation/DetailsPanel/Modals/IncidentDeleteModal.vue'
import IncidentDetailsModal from '@mobile/views/Relation/DetailsPanel/Modals/IncidentDetailsModal.vue'
import SwapIncidentModal from '@mobile/views/Relation/DetailsPanel/Modals/SwapIncidentModal/SwapIncidentModal.vue'
import { resolveEventSelectOptions } from '@mobile/views/Relation/DetailsPanel/resolveEventSelectOptions'
import { useSportOptions } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions'
import { useEventRelationSettings } from '@mobile/views/Relation/DetailsPanel/Sports/useEventRelationSettings'
import PinnedIncidentsTable from '@mobile/views/Relation/DetailsPanel/Table/PinnedIncidentsTable.vue'
import { useHistoryIncidents } from '@mobile/views/Relation/DetailsPanel/Table/useHistoryIncidents'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import EventHeaderNotificationsButton from '../Shared/EventHeader/Buttons/EventHeaderNotificationsButton.vue'
import EventHeaderQueueButton from '../Shared/EventHeader/Buttons/EventHeaderQueueButton.vue'
import { HeaderPopupKey } from '../Shared/EventHeader/HeaderPopupKey'
import NotificationsView from '../Shared/Popups/Notifications/NotificationsView.vue'
import Queue from '../Shared/Popups/Queue/Queue.vue'
import { useEventInjections } from '../Shared/RelationDependencies/useEventInjections'
import Summary from './components/Summary/Summary.vue'
import KeyPassessFilterToggle from './Filters/Toggles/KeyPasssesFilterToggle.vue'
import IncidentAddModal from './Modals/IncidentAddModal.vue'
import PocketIncidents from './PocketIncidents/PocketIncidents.vue'
import HistoryTable from './Table/HistoryTable.vue'

export default defineComponent({
  components: {
    Summary,
    EventHeaderQueueButton,
    EventHeaderNotificationsButton,
    Button,
    NotificationsView,
    Queue,
    HistoryTable,
    Select,
    ToggleSwitch,
    IncidentAddModal,
    ProtocolHeaderButton,
    TrackerHeaderButton,
    PinnedIncidentsTable,
    IncidentDetailsModal,
    IncidentDeleteModal,
    SwapIncidentModal,
    PocketIncidents,
    KeyPassessFilterToggle,
  },
  setup() {
    const eventInjections = useEventInjections()
    const {
      probableHomeParticipant,
      probableAwayParticipant,
      probableFirstParticipant,
      probableSecondParticipant,
      probableEvent,
      eventsScouts,
      sport,
    } = eventInjections
    const sportOptionsComposable = useSportOptions(sport)
    const { detailsIncidents: selectedDetailsIncidents } =
      useEventRelationSettings(eventInjections, sportOptionsComposable)
    const {
      hideIncidentsOptions,
      participantIncidentsOptions,
      detailsIncidentsOptions,
    } = sportOptionsComposable
    const { eventParticipantsSelectOptions } =
      resolveEventSelectOptions(probableEvent)
    const {
      incidentType,
      hideIncidents,
      detailsIncidents,
      participantsIncidents,
      enableFilters,
      emptyIncidents,
      eventParticipant,
      load: loadSettings,
    } = useEventRelationSettings(eventInjections, sportOptionsComposable)
    const currentPopupOpened: Ref<HeaderPopupKey | null> = ref(null)
    const showIncidentAddModal = ref(false)
    const firstScout = computed(() =>
      eventsScouts.value.find((scout) => scout.rank === EventScoutRank.Main),
    )
    const secondScout = computed(() =>
      eventsScouts.value.find((scout) => scout.rank === EventScoutRank.Helper),
    )
    const { pinnedIncidentIds, selectIncident, selectedIncident } =
      useHistoryIncidents(eventInjections)

    const showIncidentDetailsModal = ref(false)
    const showIncidentDeleteModal = ref(false)
    const showSwapIncidentModal = ref(false)

    const scrollY = ref(0)

    // TODO: it needs to be refactored but in another task
    const showKeyPassesFilter = computed(() => {
      return selectedDetailsIncidents.value.some((incidentId) => {
        return [
          SoccerIncidentId.InPossession,
          SoccerIncidentId.Attack,
          SoccerIncidentId.DangerousAttack,
        ].includes(incidentId as SoccerIncidentId)
      })
    })

    function openPopup(key: HeaderPopupKey): void {
      if (currentPopupOpened.value === key) {
        currentPopupOpened.value = null

        return
      }

      currentPopupOpened.value = key
    }

    function closePopups(): void {
      currentPopupOpened.value = null
    }

    loadSettings()

    function openIncidentsDetailModal(
      linkedIncident: ProbableLinkedIncident,
    ): void {
      selectIncident(linkedIncident)
      showIncidentDetailsModal.value = true
    }

    function openSwapIncidentModal(
      linkedIncident: ProbableLinkedIncident,
    ): void {
      selectIncident(linkedIncident)
      showSwapIncidentModal.value = true
    }

    function openIncidentsDeleteModal(
      linkedIncident: ProbableLinkedIncident,
    ): void {
      selectIncident(linkedIncident)
      showIncidentDeleteModal.value = true
    }

    function updateScroll(): void {
      scrollY.value = window.scrollY
    }

    onMounted(() => {
      document.addEventListener('scroll', updateScroll)
    })

    onUnmounted(() => {
      document.removeEventListener('scroll', updateScroll)
    })

    return {
      scrollY,
      selectedIncident,
      showIncidentDetailsModal,
      showIncidentDeleteModal,
      showSwapIncidentModal,
      openIncidentsDetailModal,
      openIncidentsDeleteModal,
      openSwapIncidentModal,
      probableHomeParticipant,
      probableAwayParticipant,
      probableFirstParticipant,
      probableSecondParticipant,
      getTeamSide,
      currentPopupOpened,
      probableEvent,
      HeaderPopupKey,
      closePopups,
      openPopup,
      hideIncidents,
      participantsIncidents,
      detailsIncidents,
      emptyIncidents,
      eventParticipantsSelectOptions,
      hideIncidentsOptions,
      participantIncidentsOptions,
      detailsIncidentsOptions,
      enableFilters,
      showIncidentAddModal,
      firstScout,
      secondScout,
      pinnedIncidentIds,
      incidentType,
      eventParticipant,
      nullCoalesce,
      opChain,
      showKeyPassesFilter,
    }
  },
})
</script>

<template>
  <div class="relative">
    <div
      class="bg-silver sticky inset-0 z-20"
      :class="{ 'shadow-md': scrollY > 0 }"
    >
      <div class="flex justify-between py-4">
        <div class="space-y-4">
          <div class="flex items-center space-x-4 px-4">
            <div>
              <span class="font-bold">
                {{ probableHomeParticipant.short_name }}
              </span>
              -
              <span class="font-bold">
                {{ probableAwayParticipant.short_name }}
              </span>
            </div>
          </div>
          <div class="flex space-x-6 px-4 text-sm">
            <div class="space-x-1">
              <span class="text-cloudygray">First Scout:</span>
              <span class="font-bold">
                {{
                  nullCoalesce(
                    opChain(firstScout, (v) => v.full_name),
                    '---',
                  )
                }}
              </span>
            </div>
            <div class="space-x-1">
              <span class="text-cloudygray">Second Scout:</span>
              <span class="font-bold">
                {{
                  nullCoalesce(
                    opChain(secondScout, (v) => v.full_name),
                    '---',
                  )
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex px-4">
          <TrackerHeaderButton />
          <ProtocolHeaderButton />
          <div class="relative inline-block">
            <EventHeaderNotificationsButton @click="openPopup" />
            <div
              v-if="currentPopupOpened === HeaderPopupKey.Notifications"
              class="absolute inset-0 h-full"
              @click="currentPopupOpened = null"
            >
              <div
                class="absolute right-0 top-10 z-20 bg-white shadow-md"
                style="width: 700px"
                @click.stop=""
              >
                <NotificationsView class="shadow-md" />
              </div>
            </div>
          </div>
          <div class="relative inline-block">
            <EventHeaderQueueButton @click="openPopup" />
            <div
              v-if="currentPopupOpened === HeaderPopupKey.Queue"
              class="absolute inset-0 h-full"
              @click="currentPopupOpened = null"
            >
              <div
                class="absolute right-0 top-10 z-20 bg-white shadow-md"
                style="width: 700px"
                @click.stop=""
              >
                <Queue />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="text-lightgray" />
    </div>

    <div class="pb-4">
      <Summary />
    </div>

    <hr class="text-lightgray mx-4 mb-4" />

    <div class="px-4 pb-4">
      <div class="flex flex-wrap items-end gap-4">
        <div
          class="flex flex-col"
          style="width: 200px"
        >
          <span class="pb-1 text-xs">Hide incidents</span>
          <Select
            v-model="hideIncidents"
            label="name"
            :options="hideIncidentsOptions"
            mode="multiple"
            trackBy="name"
            searchable
            customLabelSlot
            data-test-id="DetailsPanelHideIncidentsSelect"
          />
        </div>
        <div
          class="flex flex-col"
          style="width: 200px"
        >
          <span class="pb-1 text-xs">Participants incidents</span>
          <Select
            v-model="participantsIncidents"
            label="name"
            :options="participantIncidentsOptions"
            mode="multiple"
            trackBy="name"
            searchable
            customLabelSlot
            data-test-id="DetailsPanelParticipantsIncidentsSelect"
          />
        </div>
        <div
          class="flex flex-col"
          style="width: 200px"
        >
          <span class="pb-1 text-xs">Details incidents</span>
          <Select
            v-model="detailsIncidents"
            label="name"
            :options="detailsIncidentsOptions"
            mode="multiple"
            trackBy="name"
            searchable
            customLabelSlot
            data-test-id="DetailsPanelDetailsIncidentsSelect"
          />
        </div>
        <div
          class="flex flex-col"
          style="width: 200px"
        >
          <span class="pb-1 text-xs">Incident type</span>
          <Select
            v-model="incidentType"
            label="name"
            :options="participantIncidentsOptions"
            mode="multiple"
            trackBy="name"
            searchable
            customLabelSlot
            data-test-id="DetailsPanelIncidentTypeSelect"
          />
        </div>
        <div
          class="flex flex-col"
          style="width: 200px"
        >
          <span class="pb-1 text-xs">Participant</span>
          <Select
            v-model="eventParticipant"
            label="name"
            mode="single"
            :options="eventParticipantsSelectOptions"
            clearable
            data-test-id="DetailsPanelParticipantSelect"
          />
        </div>
        <div class="flex flex-col">
          <span class="pb-1 text-xs">Empty incidents</span>
          <ToggleSwitch
            v-model:value="emptyIncidents"
            class="-ml-2 py-2"
          />
        </div>
        <KeyPassessFilterToggle v-if="showKeyPassesFilter" />
        <div class="flex flex-col">
          <span class="pb-1 text-xs">Enable filters</span>
          <ToggleSwitch
            v-model:value="enableFilters"
            class="-ml-2 py-2"
          />
        </div>
        <Button
          :size="'xs'"
          :color="'blue'"
          data-test-id="ManualAddIncidentButton"
          @click="showIncidentAddModal = !showIncidentAddModal"
        >
          Manual add incident
        </Button>
      </div>
    </div>

    <PocketIncidents class="pb-6" />

    <div class="space-y-4 px-4">
      <div class="max-w-screen overflow-x-scroll">
        <PinnedIncidentsTable
          v-if="pinnedIncidentIds.length"
          @openIncidentsDeleteModal="openIncidentsDeleteModal"
          @openIncidentsDetailModal="openIncidentsDetailModal"
          @openSwapIncidentModal="openSwapIncidentModal"
        />
      </div>
      <div class="max-w-screen overflow-x-scroll">
        <HistoryTable
          @openIncidentsDeleteModal="openIncidentsDeleteModal"
          @openIncidentsDetailModal="openIncidentsDetailModal"
          @openSwapIncidentModal="openSwapIncidentModal"
        />
      </div>
    </div>

    <IncidentAddModal
      v-if="showIncidentAddModal"
      @close="showIncidentAddModal = false"
    />
    <IncidentDetailsModal
      v-if="showIncidentDetailsModal && selectedIncident"
      :linkedIncident="selectedIncident"
      @close="showIncidentDetailsModal = false"
    />
    <IncidentDeleteModal
      v-if="showIncidentDeleteModal && selectedIncident"
      :linkedIncident="selectedIncident"
      @close="showIncidentDeleteModal = false"
    />
    <SwapIncidentModal
      v-if="showSwapIncidentModal && selectedIncident"
      :linkedIncident="selectedIncident"
      @close="showSwapIncidentModal = false"
    />
  </div>
</template>
