<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import SwapIncident from '@mobile/ActionQueue/Actions/Incidents/SwapIncident'

import QueueAction from './QueueAction.vue'

export default defineComponent({
  components: { QueueAction },
  props: {
    action: {
      type: Object as PropType<SwapIncident>,
      required: true,
    },
  },
  setup(props) {
    const info = computed(() =>
      JSON.stringify(props.action.swapIncidentDTO, undefined, 2),
    )

    return { info }
  },
})
</script>

<template>
  <QueueAction
    :action
    :actionInformation="'Swap incident'"
  >
    <template #infoPanel>
      {{ info }}
    </template>
  </QueueAction>
</template>
