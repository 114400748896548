import {
  Authorization,
  Credentials,
  isWretchError,
  RefreshTokenResponse,
} from '@collector/sportsapi-client-legacy'
import * as localStorage from '@mobile/globalState/localStorage'
import { useLogout } from '@mobile/views/Login/useLogout'

export class ApplicationAuthorization extends Authorization {
  public fillCredentials: (credentials: Credentials) => void = () => {}
  private static reauthorizationInProgress = false

  async requestCredentials(): Promise<Credentials> {
    return await new Promise<Credentials>((resolve) => {
      this.fillCredentials = resolve
    })
  }

  async reauthorize(): Promise<string | undefined> {
    if (ApplicationAuthorization.reauthorizationInProgress) {
      return
    }

    ApplicationAuthorization.reauthorizationInProgress = true

    const refreshToken = localStorage.getSportsApiRefreshToken()
    const authToken = localStorage.getSportsAPIJWT()
    try {
      const response = await this.client
        .headers({
          'x-statscore-app': 'collector',
          Authorization: `Bearer ${refreshToken}`,
        })
        .post({ access_token: authToken }, '/auth/refresh')
        .json<RefreshTokenResponse>()

      const { token, refresh_token } = response.api.data

      this.setAuthToken(token)
      localStorage.setSportsAPIJWT(token)
      localStorage.setSportsApiRefreshToken(refresh_token)

      const { scheduleLogout } = useLogout()
      scheduleLogout()

      return token
    } catch (error) {
      if (isWretchError(error)) {
        if (error.status === 401) {
          super.executeRefreshTokenExpirationCallbacks()
          return
        }
        throw error
      }

      return
    } finally {
      ApplicationAuthorization.reauthorizationInProgress = false
    }
  }

  public override async login(
    email: string,
    password: string,
  ): Promise<{
    token: string
    refreshToken: string
  }> {
    const { token, refreshToken } = await super.login(email, password)
    localStorage.setSportsAPIJWT(token)
    localStorage.setSportsApiRefreshToken(refreshToken)

    const { scheduleLogout } = useLogout()
    scheduleLogout()
    this.setAuthToken(token)
    super.executeLoggedInCallbacks()
    return {
      token,
      refreshToken,
    }
  }
}
