import dayjs from 'dayjs'
import Dexie, { DexieOptions } from 'dexie'
import { chain } from 'lodash'

import { StatusType } from '@collector/sportsapi-client-legacy'
import { DatabasePrefix } from '@mobile/database/consts'
import { ErrorsRepository } from '@mobile/database/ErrorsRepository'
import { EventsRepository } from '@mobile/database/EventsRepository'
import { IncidentsRepository } from '@mobile/database/IncidentsRepository'
import { QueueActionsRepository } from '@mobile/database/QueueActionsRepository'
import { RefereesRepository } from '@mobile/database/RefereesRepository'
import { SkinsRepository } from '@mobile/database/SkinsRepository'
import { SportsRepository } from '@mobile/database/SportsRepository'
import {
  UserLoginAction,
  UserLoginActionsRepository,
} from '@mobile/database/UserLoginActionsRepository'
import * as localStorage from '@mobile/globalState/localStorage'

import { EventScoutsRepository } from './EventScoutsRepository'
import { EventSettingsRepository } from './EventSettingsRepository'
import { LineupsRepository } from './LineupsRepository'
import { AppNotificationsRepository } from './NotificationsRepository'
import { SeasonParticipantsRepository } from './SeasonParticipantsRepository'

export function reconnectDatabase(): AppDatabase | null {
  const authInfo = localStorage.getAuthInfo()

  if (!authInfo) {
    return null
  }

  return new AppDatabase(`${DatabasePrefix}${authInfo?.id}`)
}

export class AppDatabase extends Dexie {
  public events: EventsRepository
  public incidents: IncidentsRepository
  public errors: ErrorsRepository
  public sports: SportsRepository
  public skins: SkinsRepository
  public lineups: LineupsRepository
  public seasonParticipants: SeasonParticipantsRepository
  public notifications: AppNotificationsRepository
  public referees: RefereesRepository
  public queueActions: QueueActionsRepository
  public userLoginActions: UserLoginActionsRepository
  public eventSettingsRepository: EventSettingsRepository
  public eventsScouts: EventScoutsRepository

  constructor(databaseName: string, options?: DexieOptions) {
    super(databaseName, options)

    this.version(1).stores({
      [EventsRepository.tableName]: EventsRepository.indexes,
      [IncidentsRepository.tableName]: IncidentsRepository.indexes,
      [ErrorsRepository.tableName]: ErrorsRepository.indexes,
      [SportsRepository.tableName]: SportsRepository.indexes,
    })
    this.version(2).stores({
      [QueueActionsRepository.tableName]: QueueActionsRepository.indexes,
    })
    this.version(3).stores({
      [UserLoginActionsRepository.tableName]:
        UserLoginActionsRepository.indexes,
    })
    this.version(4).stores({
      [SkinsRepository.tableName]: SkinsRepository.indexes,
    })
    this.version(5).stores({
      [AppNotificationsRepository.tableName]:
        AppNotificationsRepository.indexes,
      [RefereesRepository.tableName]: RefereesRepository.indexes,
    })
    this.version(6).stores({
      [EventSettingsRepository.tableName]: EventSettingsRepository.indexes,
    })
    this.version(7).stores({
      [LineupsRepository.tableName]: LineupsRepository.indexes,
    })
    this.version(8).stores({
      [SeasonParticipantsRepository.tableName]:
        SeasonParticipantsRepository.indexes,
    })
    this.version(9).stores({
      [LineupsRepository.tableName]: LineupsRepository.indexes,
    })
    this.version(10).stores({
      [EventScoutsRepository.tableName]: EventScoutsRepository.indexes,
    })
    this.version(11).stores({
      [EventScoutsRepository.tableName]: EventScoutsRepository.indexes,
    })

    this.events = new EventsRepository(this)
    this.incidents = new IncidentsRepository(this)
    this.errors = new ErrorsRepository(this)
    this.sports = new SportsRepository(this)
    this.queueActions = new QueueActionsRepository(this)
    this.userLoginActions = new UserLoginActionsRepository(this)
    this.skins = new SkinsRepository(this)
    this.referees = new RefereesRepository(this)
    this.notifications = new AppNotificationsRepository(this)
    this.eventSettingsRepository = new EventSettingsRepository(this)
    this.lineups = new LineupsRepository(this)
    this.seasonParticipants = new SeasonParticipantsRepository(this)
    this.eventsScouts = new EventScoutsRepository(this)
  }

  public async clearAll(): Promise<void> {
    await Promise.all(this.tables.map((table) => table.clear()))
  }

  public async cleanUpFromFinishedEvents(): Promise<void> {
    const events = await this.events.getAll()
    const eventsData = events.map(
      ({ eventId, data: { status_type }, pushMessageUt }) => ({
        eventId,
        statusType: status_type,
        pushMessageUt: dayjs.unix(pushMessageUt),
      }),
    )

    const now = dayjs()
    const eventsIds = eventsData
      .filter(({ statusType, pushMessageUt }) => {
        return (
          [
            StatusType.Deleted,
            StatusType.Finished,
            StatusType.Cancelled,
          ].includes(statusType) &&
          dayjs(now).diff(dayjs(pushMessageUt), 'days') >
            Number(import.meta.env.VITE_CLEAN_OLD_EVENTS_FINISHED_RETENTION)
        )
      })
      .map(({ eventId }) => eventId)

    for (const eventId of eventsIds) {
      await this.clearEvent(eventId)
    }
  }

  public async clearEvent(eventId: number): Promise<unknown[]> {
    return Promise.all([
      this.errors.removeByEventId(eventId),
      this.queueActions.removeByEventId(eventId),
      this.incidents.removeByEventId(eventId),
      this.skins.removeByEventId(eventId),
      this.referees.removeByEventId(eventId),
      this.notifications.removeByEventId(eventId),
      this.events.removeById(eventId),
      this.eventSettingsRepository.removeByEventId(eventId),
      this.lineups.removeByEventId(eventId),
      this.seasonParticipants.removeByEventId(eventId),
      this.eventsScouts.removeByEventId(eventId),

      this.events.removeById(eventId),
    ])
  }

  public static async limitDatabasesByMostRecent(
    currentDatabaseName: string,
    limit: number,
  ): Promise<void> {
    const databaseNames = await Dexie.getDatabaseNames()
    const otherDatabaseNames = databaseNames.filter(
      (databaseName) =>
        databaseName.startsWith(DatabasePrefix) &&
        databaseName !== currentDatabaseName,
    )
    const numberOfDatabasesToDelete = otherDatabaseNames.length - limit + 1
    const userLogins: UserLoginAction[] = []

    for (const databaseName of otherDatabaseNames) {
      const database = new AppDatabase(databaseName)
      await database.open()
      const userLogin = await database.userLoginActions.getOne()
      if (userLogin) {
        userLogins.push(userLogin)
      }
      database.close()
    }

    const databasesToRemove = chain(userLogins)
      .orderBy(({ lastLoginTimestamp }) => lastLoginTimestamp, 'asc')
      .slice(0, numberOfDatabasesToDelete)
      .map(({ userId }) => `${DatabasePrefix}${userId}`)
      .value()

    for (const databaseName of databasesToRemove) {
      await Dexie.delete(databaseName)
    }
  }
}
