import {
  SwappableIncidentsConfiguration,
  SwappableIncidentsConfigurationMap,
} from '@collector/sportsapi-client-legacy'
import * as sportsAPI from '@mobile/globalState/sportsAPI'

const state: {
  swappableIncidentsConfigs: SwappableIncidentsConfigurationMap
} = {
  swappableIncidentsConfigs: {},
}

export type UseSwappableIncidents = {
  clear: () => void
  initForEvent: (eventId: number) => Promise<void>
  getConfigForIncident: (
    incidentId: number,
  ) => SwappableIncidentsConfiguration | undefined
}

export function useSwappableIncidents(): UseSwappableIncidents {
  async function initForEvent(eventId: number): Promise<void> {
    const swappableIncidents =
      await sportsAPI.state.client.getEventSwappableIncidents(eventId)

    state.swappableIncidentsConfigs = swappableIncidents.api.data
  }

  function getConfigForIncident(
    incidentId: number,
  ): SwappableIncidentsConfiguration | undefined {
    return state.swappableIncidentsConfigs[incidentId]
  }

  function clear(): void {
    state.swappableIncidentsConfigs = {}
  }

  return {
    clear,
    initForEvent,
    getConfigForIncident,
  }
}
