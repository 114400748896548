import { AuthInfoObject } from '@collector/sportsapi-client-legacy'

export enum ItemKey {
  SportsAPIJWT = 'SportsAPIJWT',
  SportsApiRefreshToken = 'SportsApiRefreshToken',
  AuthInfo = 'AuthInfo',
  LastUserEmail = 'LastUserEmail',
}

export function getAuthInfo(): AuthInfoObject | undefined {
  const localStorageAuthInfo = localStorage.getItem(ItemKey.AuthInfo)
  if (localStorageAuthInfo) {
    return JSON.parse(localStorageAuthInfo)
  } else return undefined
}
export function setAuthInfo(authInfo: AuthInfoObject): void {
  localStorage.setItem(ItemKey.AuthInfo, JSON.stringify(authInfo))
}

export function getSportsApiRefreshToken(): string | undefined {
  return localStorage.getItem(ItemKey.SportsApiRefreshToken) ?? undefined
}

export function setSportsApiRefreshToken(refreshToken: string): void {
  localStorage.setItem(ItemKey.SportsApiRefreshToken, refreshToken)
}

export function getSportsAPIJWT(): string | undefined {
  return localStorage.getItem(ItemKey.SportsAPIJWT) ?? undefined
}
export function setSportsAPIJWT(sportsAPIJWT: string): void {
  localStorage.setItem(ItemKey.SportsAPIJWT, sportsAPIJWT)
}

export function getLastUserEmail(): string | undefined {
  return localStorage.getItem(ItemKey.LastUserEmail) ?? undefined
}
export function setLastUserEmail(lastUserEmail: string): void {
  localStorage.setItem(ItemKey.LastUserEmail, lastUserEmail)
}

export function removeItem(key: ItemKey): void {
  localStorage.removeItem(key)
}
