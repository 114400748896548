export enum BasketballTwoPointShotMadeAttribute {
  JumpShot = 651,
  Layup = 652,
  Dunk = 653,
  Hook = 654,
  PointsFromTurnovers = 724,
  PointsInThePaint = 725,
  SecondChancePoints = 726,
  FastBreakPoints = 727,
}
