import {
  AttackAttribute,
  DangerousAttackAttribute,
  DetailType,
  FoulAttribute,
  FreeKickAttribute,
  IncidentAttributeId,
  InPossessionAttribute,
  MissedPenaltyAttribute,
  PenaltySavedByGoalkeeperAttribute,
  RedCardAttribute,
  ShotBlockedAttribute,
  ShotOffTargetAttribute,
  ShotOnTargetAttribute,
  SoccerAttributeId,
  SoccerGoalAttribute,
  SoccerIncidentId,
  VideoAssistantRefereeAttribute,
  YellowCardAttribute,
} from '@collector/sportsapi-client-legacy'
import {
  SportAttributesConfiguration,
  SportDetailsConfiguration,
} from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'

export enum SoccerGroupName {
  Distance = 'Distance',
  PartOfBody = 'Part of body',
  Placement = 'Placement',
  ShotWoodwork = 'Shot woodwork',
  Goalkeeper = 'Goalkeeper',
  GoalType = 'Goal type',
  ShotType = 'Shot type',
}

export const SoccerDetailsConfiguration: SportDetailsConfiguration<
  SoccerIncidentId,
  SoccerAttributeId
> = {
  [SoccerIncidentId.Goal]: [
    {
      name: SoccerGroupName.Distance,
      attributes: [
        SoccerGoalAttribute.InsidePenaltyArea,
        SoccerGoalAttribute.OutsidePenaltyArea,
      ],
      cols: 2,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.PartOfBody,
      attributes: [
        SoccerGoalAttribute.LeftFoot,
        SoccerGoalAttribute.RightFoot,
        SoccerGoalAttribute.Head,
        SoccerGoalAttribute.OtherBodyPart,
      ],
      cols: 4,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.Placement,
      attributes: [
        SoccerGoalAttribute.UpperLeft,
        SoccerGoalAttribute.UpperMiddle,
        SoccerGoalAttribute.UpperRight,
        SoccerGoalAttribute.BottomLeft,
        SoccerGoalAttribute.BottomMiddle,
        SoccerGoalAttribute.BottomRight,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.GoalType,
      attributes: [
        SoccerGoalAttribute.PowerShot,
        SoccerGoalAttribute.Volley,
        SoccerGoalAttribute.BicycleKick,
        SoccerGoalAttribute.Chip,
        SoccerGoalAttribute.OneOnOne,
        SoccerGoalAttribute.Crossbar,
        SoccerGoalAttribute.Post,
      ],
      cols: 3,
      important: false,
      type: DetailType.Button,
      multiple: true,
    },
    {
      name: SoccerGroupName.Goalkeeper,
      attributes: [],
      important: true,
      type: DetailType.Button,
      fulfilledWhen: (_, probableIncident) => {
        return !!probableIncident?.additional_info?.goalkeeper_id
      },
    },
  ],

  [SoccerIncidentId.ShotOnTarget]: [
    {
      name: SoccerGroupName.Distance,
      attributes: [
        ShotOnTargetAttribute.InsidePenaltyArea,
        ShotOnTargetAttribute.OutsidePenaltyArea,
      ],
      cols: 2,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.PartOfBody,
      attributes: [
        ShotOnTargetAttribute.LeftFoot,
        ShotOnTargetAttribute.RightFoot,
        ShotOnTargetAttribute.Head,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.Placement,
      attributes: [
        ShotOnTargetAttribute.UpperLeft,
        ShotOnTargetAttribute.UpperMiddle,
        ShotOnTargetAttribute.UpperRight,
        ShotOnTargetAttribute.BottomLeft,
        ShotOnTargetAttribute.BottomMiddle,
        ShotOnTargetAttribute.BottomRight,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.ShotType,
      attributes: [
        ShotOnTargetAttribute.PowerShot,
        ShotOnTargetAttribute.Volley,
        ShotOnTargetAttribute.BicycleKick,
        ShotOnTargetAttribute.Chip,
        ShotOnTargetAttribute.OneOnOne,
      ],
      cols: 3,
      important: false,
      type: DetailType.Button,
      multiple: true,
    },
    {
      name: SoccerGroupName.Goalkeeper,
      attributes: [],
      important: true,
      type: DetailType.Button,
      fulfilledWhen: (_, probableIncident) => {
        const hasGoallineClearance = !!probableIncident?.attribute_ids.includes(
          ShotOnTargetAttribute.GoallineClearance,
        )

        return !hasGoallineClearance
          ? !!probableIncident?.additional_info?.goalkeeper_id
          : true
      },
    },
  ],
  [SoccerIncidentId.ShotOffTarget]: [
    {
      name: SoccerGroupName.Distance,
      attributes: [
        ShotOffTargetAttribute.InsidePenaltyArea,
        ShotOffTargetAttribute.OutsidePenaltyArea,
      ],
      cols: 2,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.PartOfBody,
      attributes: [
        ShotOffTargetAttribute.LeftFoot,
        ShotOffTargetAttribute.RightFoot,
        ShotOffTargetAttribute.Head,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.ShotWoodwork,
      attributes: [ShotOffTargetAttribute.ShotWoodwork],
      cols: 1,
      important: false,
      type: DetailType.Button,
      fulfilledWhen(attributes: IncidentAttributeId[]) {
        if (!attributes.includes(ShotOffTargetAttribute.ShotWoodwork))
          return true

        return attributes.some(
          (a) =>
            a === ShotOffTargetAttribute.Crossbar ||
            a === ShotOffTargetAttribute.Post,
        )
      },
    },
    {
      name: '',
      attributes: [
        ShotOffTargetAttribute.Post,
        ShotOffTargetAttribute.Crossbar,
      ],
      cols: 2,
      important: false,
      type: DetailType.Button,
      activeWith: ShotOffTargetAttribute.ShotWoodwork,
      activeWhen: (selectedAttributes) =>
        selectedAttributes.includes(ShotOffTargetAttribute.ShotWoodwork),
    },
    {
      name: SoccerGroupName.ShotType,
      attributes: [
        ShotOffTargetAttribute.Volley,
        ShotOffTargetAttribute.BicycleKick,
        ShotOffTargetAttribute.Chip,
        ShotOffTargetAttribute.OneOnOne,
      ],
      cols: 4,
      important: false,
      type: DetailType.Button,
      multiple: true,
    },
  ],
  [SoccerIncidentId.ShotBlocked]: [
    {
      name: SoccerGroupName.Distance,
      attributes: [
        ShotBlockedAttribute.InsidePenaltyArea,
        ShotBlockedAttribute.OutsidePenaltyArea,
      ],
      cols: 2,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.PartOfBody,
      attributes: [
        ShotBlockedAttribute.LeftFoot,
        ShotBlockedAttribute.RightFoot,
        ShotBlockedAttribute.Head,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.ShotType,
      attributes: [
        ShotBlockedAttribute.Volley,
        ShotBlockedAttribute.BicycleKick,
        ShotBlockedAttribute.Chip,
      ],
      cols: 3,
      important: false,
      type: DetailType.Button,
      multiple: true,
    },
  ],
  [SoccerIncidentId.MissedPenalty]: [
    {
      name: SoccerGroupName.PartOfBody,
      attributes: [
        MissedPenaltyAttribute.LeftFoot,
        MissedPenaltyAttribute.RightFoot,
      ],
      cols: 2,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.ShotWoodwork,
      attributes: [MissedPenaltyAttribute.ShotWoodwork],
      cols: 1,
      important: false,
      type: DetailType.Button,
    },
  ],
  [SoccerIncidentId.PenaltySavedByGoalkeeper]: [
    {
      name: SoccerGroupName.PartOfBody,
      attributes: [
        PenaltySavedByGoalkeeperAttribute.LeftFoot,
        PenaltySavedByGoalkeeperAttribute.RightFoot,
      ],
      cols: 2,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.Placement,
      attributes: [
        PenaltySavedByGoalkeeperAttribute.UpperLeft,
        PenaltySavedByGoalkeeperAttribute.UpperMiddle,
        PenaltySavedByGoalkeeperAttribute.UpperRight,
        PenaltySavedByGoalkeeperAttribute.BottomLeft,
        PenaltySavedByGoalkeeperAttribute.BottomMiddle,
        PenaltySavedByGoalkeeperAttribute.BottomRight,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
    {
      name: SoccerGroupName.Goalkeeper,
      attributes: [],
      important: true,
      type: DetailType.Button,
      fulfilledWhen: (_, probableIncident) => {
        return !!probableIncident?.additional_info?.goalkeeper_id
      },
    },
  ],
}

export const SoccerAttributesConfiguration: SportAttributesConfiguration<
  SoccerIncidentId,
  SoccerAttributeId
> = {
  [SoccerIncidentId.Goal]: [
    SoccerGoalAttribute.OwnGoal,
    SoccerGoalAttribute.PenaltyGoal,
  ],
  [SoccerIncidentId.RedCard]: [
    RedCardAttribute.SecondYellowCard,
    RedCardAttribute.OffField,
  ],
  [SoccerIncidentId.YellowCard]: [YellowCardAttribute.OffField],
  [SoccerIncidentId.ShotOffTarget]: [
    ShotOffTargetAttribute.ShotWoodwork,
    ShotOffTargetAttribute.Post,
    ShotOffTargetAttribute.Crossbar,
  ],
  [SoccerIncidentId.MissedPenalty]: [MissedPenaltyAttribute.ShotWoodwork],
  [SoccerIncidentId.FreeKick]: [FreeKickAttribute.DangerousFreeKick],
  [SoccerIncidentId.VideoAssistantReferee]: [
    VideoAssistantRefereeAttribute.PossibleGoal,
    VideoAssistantRefereeAttribute.PossiblePenalty,
    VideoAssistantRefereeAttribute.PossibleRedCard,
  ],
  [SoccerIncidentId.ShotOnTarget]: [ShotOnTargetAttribute.GoallineClearance],
  [SoccerIncidentId.Foul]: [FoulAttribute.CausesPenalty],
  [SoccerIncidentId.Attack]: [AttackAttribute.KeyPass],
  [SoccerIncidentId.DangerousAttack]: [DangerousAttackAttribute.KeyPass],
  [SoccerIncidentId.InPossession]: [InPossessionAttribute.KeyPass],
}
