import { computed, ComputedRef, Ref, ref, watch } from 'vue'

import { isIncidentEditable } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { EventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

const pinnedIncidentIds: Ref<string[]> = ref([])
const selectedIncident: Ref<ProbableLinkedIncident | null> = ref(null)

export type HistoryIncidentsReturnType = {
  selectIncident: (
    probableLinkedIncident: ProbableLinkedIncident | null,
  ) => void
  isIncidentSelected: (
    probableLinkedIncident: ProbableLinkedIncident,
  ) => boolean
  toggleIncidentPinState: (
    probableLinkedIncident: ProbableLinkedIncident,
  ) => void
  selectedIncident: ComputedRef<ProbableLinkedIncident | null>
  pinnedIncidentIds: Ref<string[]>
  isIncidentPinned: (probableLinkedIncident: ProbableLinkedIncident) => boolean
  clearSelection: () => void
}

export function useHistoryIncidents(
  eventInjections: EventInjections,
): HistoryIncidentsReturnType {
  const { sport, linkedProbableIncidents } = eventInjections

  watch(linkedProbableIncidents, (newProbableIncidents) => {
    selectedIncident.value =
      newProbableIncidents.find(
        (probableIncident) =>
          probableIncident.incident.id === selectedIncident.value?.incident.id,
      ) || null
  })

  function toggleIncidentPinState(
    probableLinkedIncident: ProbableLinkedIncident,
  ): void {
    if (!isIncidentEditable(probableLinkedIncident.incident, sport)) {
      return
    }

    const index = pinnedIncidentIds.value.findIndex(
      (pinnedIncidentId) =>
        pinnedIncidentId === probableLinkedIncident.incident.id,
    )

    if (index !== -1) {
      pinnedIncidentIds.value.splice(index, 1)
    } else {
      pinnedIncidentIds.value.unshift(probableLinkedIncident.incident.id)
    }
  }

  function isIncidentPinned(
    probableLinkedIncident: ProbableLinkedIncident,
  ): boolean {
    return !!pinnedIncidentIds.value.find(
      (pinnedIncidentId) =>
        pinnedIncidentId === probableLinkedIncident.incident.id,
    )
  }

  function selectIncident(
    probableLinkedIncident: ProbableLinkedIncident | null,
  ): void {
    if (
      probableLinkedIncident &&
      !isIncidentEditable(probableLinkedIncident.incident, sport)
    ) {
      return
    }

    selectedIncident.value = probableLinkedIncident
  }

  function clearSelection(): void {
    selectedIncident.value = null
  }

  function isIncidentSelected(
    probableLinkedIncident: ProbableLinkedIncident,
  ): boolean {
    return (
      selectedIncident.value?.incident.id === probableLinkedIncident.incident.id
    )
  }

  return {
    selectIncident,
    clearSelection,
    isIncidentSelected,
    toggleIncidentPinState,
    selectedIncident: computed(() => selectedIncident.value),
    pinnedIncidentIds,
    isIncidentPinned,
  }
}
