<script lang="ts">
import { computed, defineComponent, ref } from 'vue'

import {
  AttackAttribute,
  Confirmation,
  DangerousAttackAttribute,
  IncidentActionType,
  InPossessionAttribute,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'
import Pagination from '@mobile/components/Pagination/Pagination.vue'
import { useSportOptions } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions'
import { useEventRelationSettings } from '@mobile/views/Relation/DetailsPanel/Sports/useEventRelationSettings'
import Table from '@mobile/views/Relation/DetailsPanel/Table/Table.vue'
import { useHistoryIncidents } from '@mobile/views/Relation/DetailsPanel/Table/useHistoryIncidents'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import { getEmptyIncidents } from './emptyIncidentsFilter'
import HistoryItem from './HistoryItem.vue'

export default defineComponent({
  components: {
    Pagination,
    HistoryItem,
    Table,
  },
  emits: [
    'openIncidentsDetailModal',
    'openIncidentsDeleteModal',
    'openSwapIncidentModal',
  ],
  setup() {
    const eventInjections = useEventInjections()
    const sportOptionsComposable = useSportOptions(eventInjections.sport)
    const { linkedProbableIncidents, sport } = eventInjections
    const { assignableIncidentsConfiguration } = useSportConfiguration()
    const {
      participantsIncidents,
      hideIncidents,
      incidentType,
      detailsIncidents,
      enableFilters,
      emptyIncidents,
      eventParticipant,
      filterKeyPasses,
    } = useEventRelationSettings(eventInjections, sportOptionsComposable)
    const { selectedIncident } = useHistoryIncidents(eventInjections)
    const { detailsConfiguration, secondParticipantIncidents } =
      useSportOptions(sport)
    const currentPage = ref(1)
    const itemsDisableState = ref(false)
    const perPage = 20

    const filteredPaginatedIncidents = computed(() => {
      const startIndex = (currentPage.value - 1) * perPage
      const endIndex = startIndex + perPage

      return filteredIncidents.value.slice(startIndex, endIndex)
    })

    function filterByParticipantWhenFilterIsEnabled(
      incidents: ProbableLinkedIncident[],
    ): ProbableLinkedIncident[] {
      return eventParticipant.value
        ? incidents.filter(
            (incident) => incident.participant?.id === eventParticipant.value,
          )
        : incidents
    }

    function containsIncidentId(
      probableLinkedIncident: ProbableLinkedIncident,
    ): (incidentId: number) => boolean {
      return (incidentId) =>
        incidentId === probableLinkedIncident.incident.incident_id
    }

    const filteredIncidents = computed(() => {
      let incidents = linkedProbableIncidents.value

      incidents = filterByParticipantWhenFilterIsEnabled(incidents)

      if (filterKeyPasses.value) {
        // TODO: it needs to be refactored but in another task
        function filterKeyPasses(
          incidents: ProbableLinkedIncident[],
        ): ProbableLinkedIncident[] {
          return incidents.filter((incident) => {
            switch (incident.incident.incident_id) {
              case SoccerIncidentId.InPossession:
                return incident.incident.attribute_ids.includes(
                  InPossessionAttribute.KeyPass,
                )
              case SoccerIncidentId.Attack:
                return incident.incident.attribute_ids.includes(
                  AttackAttribute.KeyPass,
                )
              case SoccerIncidentId.DangerousAttack:
                return incident.incident.attribute_ids.includes(
                  DangerousAttackAttribute.KeyPass,
                )
              default:
                return false
            }
          })
        }

        if (incidentType.value.length) {
          return incidents
            .filter((incident) =>
              incidentType.value.includes(incident.incident.incident_id),
            )
            .concat(
              filterByParticipantWhenFilterIsEnabled(
                filterKeyPasses(linkedProbableIncidents.value),
              ),
            )
        } else {
          incidents = filterKeyPasses(incidents)
        }
      }

      if (incidentType.value.length) {
        return incidents.filter((incident) =>
          incidentType.value.includes(incident.incident.incident_id),
        )
      }

      if (emptyIncidents.value) {
        const participantsAndDetailsIncidents = incidents.filter(
          (incident) =>
            (participantsIncidents.value.some(containsIncidentId(incident)) ||
              detailsIncidents.value.some(containsIncidentId(incident))) &&
            incident.incident.action !== IncidentActionType.DELETE &&
            incident.incident.confirmation !== Confirmation.Cancelled,
        )

        return getEmptyIncidents(
          participantsAndDetailsIncidents,
          secondParticipantIncidents,
          detailsConfiguration,
          assignableIncidentsConfiguration,
        )
      }

      if (hideIncidents.value.length && enableFilters.value) {
        incidents = incidents.filter((incident) => {
          return hideIncidents.value.every((hideIncident) => {
            return hideIncident !== incident.incident.incident_id
          })
        })
      }

      // TODO: consider move this line to other file to sort also incidents on mobile version of Collector
      incidents.sort(({ incident: aIncident }, { incident: bIncident }) => {
        const aTime = aIncident.event_time?.split(':').map(Number) ?? [0, 0]
        const bTime = bIncident.event_time?.split(':').map(Number) ?? [0, 0]

        if (aIncident.event_status_id === bIncident.event_status_id) {
          if (bTime[0] !== aTime[0]) {
            return bTime[0] - aTime[0]
          }

          return bTime[1] - aTime[1]
        } else {
          return 1
        }
      })

      return incidents
    })

    return {
      filteredPaginatedIncidents,
      currentPage,
      perPage,
      filteredIncidents,
      selectedIncident,
      itemsDisableState,
    }
  },
})
</script>

<template>
  <div>
    <Table class="history-table">
      <HistoryItem
        v-for="linkedIncident of filteredPaginatedIncidents"
        :key="`paginated-incident-${linkedIncident.incident.id}`"
        v-bind="{ linkedIncident }"
        @openIncidentsDeleteModal="
          (linkedIncident) => $emit('openIncidentsDeleteModal', linkedIncident)
        "
        @openIncidentsDetailModal="
          (linkedIncident) => $emit('openIncidentsDetailModal', linkedIncident)
        "
        @openSwapIncidentModal="
          (linkedIncident) => $emit('openSwapIncidentModal', linkedIncident)
        "
      />
    </Table>
    <Pagination
      v-model:currentPage="currentPage"
      class="items-center justify-center py-6"
      :perPage
      :total="filteredIncidents.length"
      :currentPageTotal="filteredPaginatedIncidents.length"
    />
  </div>
</template>

<style>
.history-table:has(.table-row.docked) .table-row:not(.docked) {
  opacity: 0.25;
  pointer-events: none;
}

.history-table:has(.table-row.docked) .table-row:not(.docked) button {
  pointer-events: none;
}
</style>
