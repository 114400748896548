import { jwtDecode, JwtPayload } from 'jwt-decode'

import * as localStorage from '@mobile/globalState/localStorage'
import { AppNotificationType } from '@mobile/reusables/types/AppNotificationType'
import { Notifications } from '@mobile/views/Relation/Shared/RelationDependencies/types'

import { Hook } from './Hook'

export class HookLogoutNotifications implements Hook {
  constructor(private readonly notifications: Notifications) {}

  timeout: number | undefined

  install(): void {
    const refreshToken = localStorage.getSportsApiRefreshToken()

    if (refreshToken) {
      const { exp } = jwtDecode<JwtPayload>(refreshToken)
      if (exp) {
        const timeToLogoutMs = exp * 1000 - new Date().getTime()
        this.timeout = window.setTimeout(
          () => {
            this.notifications.sendNotification({
              type: AppNotificationType.Warning,
              title:
                'Log out and log in, manually to avoid stressfull situations',
              content: 'You will be logged out automatically in 1 minute',
            })
          },
          Math.max(timeToLogoutMs - 60 * 1000, 60 * 1000),
        )
      }
    }
  }

  uninstall(): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }
}
