import { computed, ComputedRef } from 'vue'

import * as sportsAPI from '@mobile/globalState/sportsAPI'

export function useLoginFormVisibility(): { isVisible: ComputedRef<boolean> } {
  const isVisible = computed(
    () => !sportsAPI.state.authInfo || sportsAPI.state.tokenExpired,
  )

  return { isVisible }
}
