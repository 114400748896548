<script lang="ts">
import { Container } from 'inversify'
import {
  defineComponent,
  onBeforeUnmount,
  onUnmounted,
  Ref,
  ref,
  watch,
} from 'vue'
import {
  onBeforeRouteLeave,
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router'

import {
  CollectorPushMessageEvent,
  includesAdminRoles,
  includesControllerRole,
  RelationStatus,
} from '@collector/sportsapi-client-legacy'
import LeaveRelationConfirmationAlert from '@mobile/components/Alert/LeaveRelationConfirmationAlert.vue'
import Loader from '@mobile/components/Loader/Loader.vue'
import { reconnectDatabase } from '@mobile/database/AppDatabase'
import { closeAlert, showAlert } from '@mobile/globalState/alert'
import * as app from '@mobile/globalState/app'
import * as popup from '@mobile/globalState/popup'
import * as scoutMessenger from '@mobile/globalState/scoutMessenger'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import router from '@mobile/router'
import { bindAllAsSync } from '@mobile/views/Relation/createSyncIoC'
import EventPreparation from '@mobile/views/Relation/Shared/Popups/EventPreparation/EventPreparation.vue'
import { loadEvent } from '@mobile/views/Relation/Shared/RelationDependencies/bindEvent'
import { bindRelationDependencies } from '@mobile/views/Relation/Shared/RelationDependencies/relationDependencies'
import { RelationIoCBindings } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { EventNotificationHooks } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/EventNotificationHooks'
import { RelationSportCommonIoCBindings } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/relationSportCommonDependencies'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'
import { getSportRelation } from '@mobile/views/Relation/SportRelations'
import { useWebSocketRelationStarter } from '@mobile/views/Relation/useWebSocketRelationStarter'

import { useSportsSynchronizer } from '../Login/useSportsSynchronizer'
import { useDebugNotifications } from './useDebugNotifications'
import { useSwappableIncidents } from './useSwappableIncidents'

type LeaveRelationConfirmationAlertEvents = Parameters<
  Parameters<
    Exclude<(typeof LeaveRelationConfirmationAlert)['setup'], undefined>
  >[1]['emit']
>[0]

export default defineComponent({
  components: { Loader },
  async beforeRouteEnter(
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: (to?: RouteLocationRaw) => void,
  ) {
    if (app.state.database === null) {
      const database = reconnectDatabase()

      if (!database) {
        next({ name: 'Login', query: { redirect: to.fullPath } })
        return
      }

      app.setDatabase(database)
      app.setDatabaseAsConnected()
    }

    const { synchronizeSports } = useSportsSynchronizer(
      sportsAPI.state.client,
      app.state.database,
    )

    const asyncIoC = new Container()
    const eventId = Number(to.params.eventId)
    const event = await loadEvent(eventId)

    let sport = await app.state.database.sports.getBySportId(
      event.data.sport_id,
    )
    if (!sport) {
      await synchronizeSports()

      sport = await app.state.database.sports.getBySportId(event.data.sport_id)
      if (!sport) {
        next({ name: 'EventsList' })
        return
      }
    }

    bindRelationDependencies(asyncIoC, eventId)

    // To know sport which is required to bind common sport dependencies we need to resolve
    // factualEvent first. To resolve all dependencies (to make them sync) first we need to bind all
    // dependencies and only then we can resolve them or in other case main dependencies that are
    // dependant on common sport dependencies won't resolve.
    let sportDependencies: Record<string, unknown> = {}
    asyncIoC.onActivation(
      RelationIoCBindings.FactualEvent,
      async (ctx, factualEvent) => {
        const sportId = factualEvent.value.data.sport_id
        sportDependencies =
          getSportRelation(sportId).bindSportRelationDependencies(asyncIoC)

        return factualEvent
      },
    )

    const syncIoC = new Container()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await bindAllAsSync(asyncIoC, syncIoC, RelationIoCBindings)
    await bindAllAsSync(asyncIoC, syncIoC, {
      ...sportDependencies,
      ...RelationSportCommonIoCBindings,
    })

    await useSwappableIncidents().initForEvent(eventId)

    to.meta.ioc = syncIoC
    next()
  },
  async beforeRouteLeave() {
    useSwappableIncidents().clear()
  },
  setup() {
    const eventInjections = useEventInjections()
    const { event } = eventInjections
    const eventId = event.value.eventId
    const sportId = event.value.data.sport_id

    useWebSocketRelationStarter(scoutMessenger.state.connection, eventId)
    useEventPreparationPopupWhenRelationIsNotStarted()

    const isScoutAssignedToEvent = useIsScoutAssignedToEvent(eventId)
    useRelationRedirectWhenScoutIsUnassigned(isScoutAssignedToEvent)
    useLeaveRelationConfirmationAlert(isScoutAssignedToEvent)

    useHooks(useSportConfiguration().hooks)
    useDebugNotifications(eventInjections, scoutMessenger.state.connection)

    /**
     * TODO: remove this line after creating desktop version
     * Check https://statscore.atlassian.net/browse/COLL-625
     */
    useEventSynchronizationForControllers(eventId)

    const { component: sportRelationComponent } = getSportRelation(sportId)

    return { sportRelationComponent }
  },
})

async function useEventSynchronizationForControllers(
  eventId: number,
): Promise<void> {
  const roles = sportsAPI.state.authInfo?.roles ?? []

  if (includesControllerRole(roles) || includesAdminRoles(roles)) {
    scoutMessenger.state.connection.joinRelation(eventId)

    const databaseEvent = await app.state.database.events.getByEventId(eventId)

    const catchUpUt = databaseEvent?.pushMessageUt ?? 0

    scoutMessenger.state.connection.syncEvent(eventId, catchUpUt)
  }
}

function useHooks(hooks: EventNotificationHooks): void {
  hooks.forEach((hook) => hook.install())

  onUnmounted(() => {
    hooks.forEach((hook) => hook.uninstall())
  })
}

function useIsScoutAssignedToEvent(eventId: number): Ref<boolean> {
  const isScoutAssignedToEvent = ref(true)

  function onEventDelete(deletedEvent: CollectorPushMessageEvent): void {
    if (deletedEvent.eventId === eventId) {
      isScoutAssignedToEvent.value = false
    }
  }

  app.state.database.events.on('deleted', onEventDelete)
  onBeforeUnmount(() => {
    app.state.database.events.off('deleted', onEventDelete)
  })

  return isScoutAssignedToEvent
}

function useEventPreparationPopupWhenRelationIsNotStarted(): void {
  const { probableEvent } = useEventInjections()

  watch(
    () => probableEvent.value.relation_status,
    (relationStatus) => {
      if (relationStatus === RelationStatus.NotStarted) {
        popup.show({
          component: EventPreparation,
          showHeader: false,
        })
      }
    },
    { immediate: true },
  )
}

function useLeaveRelationConfirmationAlert(
  isScoutAssignedToEvent: Ref<boolean>,
): void {
  const { probableEvent } = useEventInjections()

  onBeforeRouteLeave((to, from, next) => {
    if (
      probableEvent.value.relation_status === RelationStatus.NotStarted ||
      !isScoutAssignedToEvent.value
    ) {
      return next()
    }

    showAlert<LeaveRelationConfirmationAlertEvents>(
      LeaveRelationConfirmationAlert,
      {
        confirm: () => {
          closeAlert()
          next()
        },
        close: () => {
          closeAlert()
          next(false)
        },
      },
    )
  })
}

function useRelationRedirectWhenScoutIsUnassigned(
  isScoutAssignedToEvent: Ref<boolean>,
): void {
  watch(isScoutAssignedToEvent, (isScoutAssignedToEvent) => {
    if (!isScoutAssignedToEvent) {
      router.replace({ name: 'EventsList' })
    }
  })
}
</script>

<template>
  <div v-if="$route.name === 'details-panel'">
    <RouterView />
  </div>
  <component
    :is="sportRelationComponent"
    v-else
    class="h-full min-h-full"
  />
</template>
