<script setup lang="ts">
import { get } from 'lodash'

import { opChain } from '@collector/shared-utils'
import Select from '@mobile/components/Select/Select.vue'
import { SelectOption } from '@mobile/components/Select/types/SelectOption'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { AssignableIncidentConfigurationParticipantType } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/AssignableIncidentsConfiguration'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

interface Props {
  valid: boolean
  options: SelectOption[]
  participantType: AssignableIncidentConfigurationParticipantType
  openedList?: boolean
  disabled?: boolean
  linkedIncident: ProbableLinkedIncident
  onSearchChange: (search: string) => void
  onUpdate: (participantId: number | null) => void
}

const props = defineProps<Props>()

// composables
const { assignableIncidentsConfiguration } = useSportConfiguration()

// data
const assignableIncidentConfiguration = get(
  assignableIncidentsConfiguration,
  props.linkedIncident.incident.incident_id,
  null,
)

// model
const selectedParticipant = defineModel<number | null>()

// methods
function getConfigParticipantName(
  key: AssignableIncidentConfigurationParticipantType,
): string | undefined {
  return opChain(assignableIncidentConfiguration, (v) => v.participants?.[key])
}

function getBorderColor(): string {
  if (props.disabled) {
    return 'border-cloudygray/20'
  }

  return props.valid ? 'border-green' : 'border-red'
}
</script>

<template>
  <div class="space-y-1">
    <div class="text-gray text-xxs font-bold">
      {{ getConfigParticipantName(participantType) }}
    </div>
    <Select
      v-model="selectedParticipant"
      :class="[getBorderColor(), 'border-2']"
      label="name"
      mode="single"
      :options
      :openedList
      searchable
      trackBy="name"
      :disabled
      data-test-id="DetailsPanelPlayerSelect"
      @searchChange="onSearchChange"
      @update:value="onUpdate"
    />
  </div>
</template>
