<script lang="ts">
import { defineComponent } from 'vue'

import Logo from '@mobile/components/Logo/Logo.vue'

import BackgroundStadium from '../BackgroundStadium/BackgroundStadium.vue'

export default defineComponent({
  components: {
    Logo,
    BackgroundStadium,
  },
})
</script>

<template>
  <div class="bg-neutralLight z-75 absolute inset-0 overflow-hidden">
    <BackgroundStadium />
    <div
      class="text-violet flex h-full w-full flex-col items-center justify-between overflow-hidden text-center font-extrabold"
    >
      <Logo class="pt-40" />
      <div class="flex flex-col items-center gap-5">
        <img
          class="w-20 pb-5"
          src="@mobile/assets/rotate.svg"
          alt="Rotate"
        />
        <div>
          <div>Rotate your mobile</div>
          <div>to the landscape orientation</div>
        </div>
      </div>
      <div class="mb-4">
        <img
          src="@mobile/assets/statscore.svg"
          alt="STATSCORE Logo"
        />
      </div>
    </div>
  </div>
</template>
