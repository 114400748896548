<script lang="ts">
import { get } from 'lodash'
import { computed, defineComponent, PropType, toRef } from 'vue'

import { opChain } from '@collector/shared-utils'
import {
  Confirmation,
  IncidentId,
  IncidentsQueuePatchDTO,
} from '@collector/sportsapi-client-legacy'
import { useLineupsSelectOptions } from '@mobile/views/Relation/Shared/Lineups/useLineupsSelectOption'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { AssignableIncidentConfigurationParticipantType } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/AssignableIncidentsConfiguration'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import MainParticipantUpdateSelect from './MainParticipantUpdateSelect.vue'
import SecondaryParticipantUpdateSelect from './SecondaryParticipantUpdateSelect.vue'
import TernaryParticipantUpdateSelect from './TernaryParticipantUpdateSelect.vue'

export default defineComponent({
  components: {
    MainParticipantUpdateSelect,
    SecondaryParticipantUpdateSelect,
    TernaryParticipantUpdateSelect,
  },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
    participantsIncidents: {
      type: Array as PropType<IncidentId[]>,
      default: () => [],
    },
    detailsIncidents: {
      type: Array as PropType<IncidentId[]>,
      default: () => [],
    },
    docked: {
      type: Boolean,
      default: false,
    },
    openedList: {
      type: Boolean,
      default: false,
    },
    isPinnedTable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    openedMainSelect: {
      type: Boolean,
      default: false,
    },
    openedSecondarySelect: {
      type: Boolean,
      default: false,
    },
    openedTernarySelect: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Object as PropType<
        Pick<IncidentsQueuePatchDTO, 'minute' | 'second'>
      >,
      required: true,
    },
  },
  setup(props) {
    const { assignableIncidentsConfiguration } = useSportConfiguration()
    const assignableIncidentConfiguration = get(
      assignableIncidentsConfiguration,
      props.linkedIncident.incident.incident_id,
      null,
    )
    const doesSportSupportsThirdParticipant = Object.keys(
      assignableIncidentsConfiguration,
    ).some((key) => {
      return assignableIncidentsConfiguration[key as unknown as IncidentId]
        ?.participants?.ternary
    })

    const showSecondaryParticipantSelect = computed<boolean>(() => {
      if (!assignableIncidentConfiguration?.participants?.secondary) {
        return false
      }

      const { availableWhen } =
        assignableIncidentConfiguration.participantsConfiguration?.secondary ??
        {}

      return availableWhen?.(toRef(props.linkedIncident.incident)) ?? true
    })

    const showTernaryParticipantSelect = computed<boolean>(() => {
      if (!assignableIncidentConfiguration?.participants?.ternary) {
        return false
      }

      const { availableWhen } =
        assignableIncidentConfiguration.participantsConfiguration?.ternary ?? {}

      return availableWhen?.(toRef(props.linkedIncident.incident)) ?? true
    })

    const { lineupsBothSideSelectOptions, ternaryLineupsParticipants } =
      useLineupsSelectOptions(
        computed(() => props.linkedIncident.incident),
        props.linkedIncident.side ?? undefined,
        assignableIncidentConfiguration,
      )

    const showParticipantsSelect = computed(() =>
      props.participantsIncidents.includes(
        props.linkedIncident.incident.incident_id,
      ),
    )

    function getConfigParticipantName(
      key: AssignableIncidentConfigurationParticipantType,
    ): string | undefined {
      return opChain(
        assignableIncidentConfiguration,
        (v) => v.participants?.[key],
      )
    }

    return {
      assignableIncidentConfiguration,
      Confirmation,
      lineupsBothSideSelectOptions,
      showParticipantsSelect,
      showSecondaryParticipantSelect,
      showTernaryParticipantSelect,
      ternaryLineupsParticipants,
      doesSportSupportsThirdParticipant,
      getConfigParticipantName,
      opChain,
    }
  },
})
</script>

<template>
  <div>
    <div class="flex flex-wrap gap-x-8 gap-y-4">
      <MainParticipantUpdateSelect
        class="w-[260px]"
        :disabled
        :linkedIncident
        :time
        :openedList="openedMainSelect"
      />
      <SecondaryParticipantUpdateSelect
        v-if="showSecondaryParticipantSelect"
        class="w-[260px]"
        :disabled
        :linkedIncident
        :time
        :openedList="openedSecondarySelect"
      />
      <TernaryParticipantUpdateSelect
        v-if="showTernaryParticipantSelect"
        class="w-[260px]"
        :disabled
        :linkedIncident
        :time
        :openedList="openedTernarySelect"
      />
    </div>
  </div>
</template>
