export enum IceHockeyStoppageAttribute {
  PuckOut = 482,
  Fight = 483,
  Icing = 484,
  Offside = 485,
  GoalieStopped = 486,
  ShiftedGoal = 487,
  Foul = 488,
  Injury = 489,
  Other = 490,
}
