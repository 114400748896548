import {
  IceHockeyAttributeIds,
  IceHockeyEventStatusId,
  IceHockeyIncidentId,
} from '@collector/sportsapi-client-legacy'

import { DetailsPanelSportConfiguration } from '../DetailsPanelSportConfigurationFactory'
import {
  IceHockeyAttributesConfiguration,
  IceHockeyDetailsConfiguration,
  IceHockeyGroupNames,
} from './IceHockeyDetailsConfiguration'
import {
  IceHockeyDetailsIncidentIds,
  IceHockeyHideIncidentIds,
  IceHockeyParticipantIncidentIds,
} from './IceHockeyFiltersIncidentIds'
import {
  IceHockeyManualAddIncidentIds,
  IceHockeyManualAddStatusIds,
} from './IceHockeyManualAddConfiguration'

export const IceHockeyConfiguration: DetailsPanelSportConfiguration<
  IceHockeyIncidentId,
  IceHockeyAttributeIds,
  IceHockeyEventStatusId
> = {
  groupNames: IceHockeyGroupNames,
  detailsConfiguration: IceHockeyDetailsConfiguration,
  attributesConfiguration: IceHockeyAttributesConfiguration,
  secondParticipantIncidents: [IceHockeyIncidentId.Goal],
  filters: {
    hideIncidents: IceHockeyHideIncidentIds,
    participantIncidents: IceHockeyParticipantIncidentIds,
    detailsIncidents: IceHockeyDetailsIncidentIds,
    selectedOnStartDetailsIncidents: IceHockeyDetailsIncidentIds,
  },
  manualAddIncidentModal: {
    statuses: IceHockeyManualAddStatusIds,
    incidents: IceHockeyManualAddIncidentIds,
  },
}
