<script lang="ts">
import { computed, defineComponent, PropType, Ref } from 'vue'

import { IncidentId } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'
import { ButtonColor, ButtonSize } from '@mobile/components/Button/ButtonTypes'
import { getParticipantButtonColor } from '@mobile/components/Button/ButtonUtils'
import { byId } from '@mobile/reusables/entityUtils'
import { BallPosition } from '@mobile/reusables/types/position/BallPosition'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import RelationIcon, {
  RelationIconType,
} from '@mobile/views/Relation/Shared/RelationIcon/RelationIcon.vue'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

export default defineComponent({
  components: {
    Button,
    RelationIcon,
  },
  props: {
    name: {
      type: String,
      required: false,
    },
    type: {
      type: String as PropType<'horizontal' | 'vertical'>,
      required: true,
    },
    incidentId: {
      type: Number as PropType<IncidentId>,
      required: true,
    },
    size: {
      type: String as PropType<ButtonSize>,
      required: true,
    },
    color: {
      type: String as PropType<ButtonColor>,
      required: false,
    },
    withIcon: {
      type: Boolean,
      default: true,
    },
    participantId: {
      type: Number as PropType<number>,
      required: false,
    },
    position: {
      type: Object as PropType<BallPosition>,
      required: false,
    },
    sendIncident: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: undefined,
      required: false,
    },
  },
  emits: ['incidentHandled'],
  setup(props, { emit }) {
    const { sport, probableEvent } = useEventInjections()
    const { incidentHandlersConfiguration } = useSportConfiguration()

    const buttonColor: Ref<ButtonColor> = computed(() => {
      const participant = byId(
        probableEvent.value.participants,
        props.participantId,
      )
      const color = props.color ?? 'white'

      return participant ? getParticipantButtonColor(participant) : color
    })

    const incidentName =
      props.name ??
      computed(() =>
        sport.value.getIncidentTranslation(Number(props.incidentId)),
      )
    const buttonContentClasses = computed(
      () =>
        ({
          horizontal: ['flex-row', 'space-x-2'],
          vertical: ['flex-col', 'space-y-2'],
        })[props.type],
    )
    const buttonConfig = computed(() => {
      const { disabled, handler } =
        incidentHandlersConfiguration[props.incidentId] ??
        incidentHandlersConfiguration.fallbackHandler

      return {
        handler(): void {
          emit('incidentHandled')
          handler({
            incidentId: props.incidentId,
            participantId: props.participantId,
            position: props.position,
          })
        },
        disabled:
          props.disabled !== undefined
            ? props.disabled
            : disabled({
                participantId: props.participantId,
                position: props.position,
                incidentId: props.incidentId,
              }),
        buttonColor: buttonColor.value,
      }
    })

    function handleOnClick(): void {
      if (!props.sendIncident) {
        return
      }

      buttonConfig.value.handler()
    }

    return {
      incidentName,
      buttonContentClasses,
      buttonConfig,
      RelationIconType,
      handleOnClick,
    }
  },
})
</script>

<template>
  <Button
    :size
    :color="buttonConfig.buttonColor"
    :disabled="disabled !== undefined ? disabled : buttonConfig.disabled"
    @click="handleOnClick"
  >
    <div
      class="flex flex-1 items-center"
      :class="buttonContentClasses"
    >
      <RelationIcon
        v-if="withIcon"
        :size="13"
        :iconId="incidentId"
        :type="RelationIconType.Incidents"
      />
      <div
        class="mb-0.5 mt-px flex flex-1 items-center text-left font-bold"
        :class="{ 'text-center': type === 'vertical' }"
      >
        <slot :incidentName>{{ incidentName }}</slot>
      </div>
    </div>
  </Button>
</template>
