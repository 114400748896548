import { Exclude } from 'class-transformer'

import { generateUniqueId } from '@mobile/uniqueId'
import { IncidentsConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/IncidentsConfiguration'

export type ActionType =
  | 'UpdateEvent'
  | 'UpdateEventParticipants'
  | 'UpdateEventTime'
  | 'AddIncident'
  | 'DeleteIncident'
  | 'UpdateIncident'
  | 'UpdateLineups'
  | 'SwapIncident'

export enum ActionStatus {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Errored = 'Errored',
  Unknown = 'Unknown',
  Completed = 'Completed',
}

export abstract class Action<ProbableResultData> {
  public readonly id: string = generateUniqueId()
  public status: ActionStatus = ActionStatus.Pending
  public error?: string
  @Exclude()
  public currentPromise?: Promise<void | string>
  public readonly actionTime: number = Date.now()
  public dependencies: string[] = []
  abstract type: ActionType

  abstract execImpl(): Promise<void | string>

  public abstract applyProbableResult(
    currentData: ProbableResultData,
    incidentsConfiguration: IncidentsConfiguration,
  ): void
}
