import {
  SoccerAttributeId,
  SoccerEventStatusId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'

import { DetailsPanelSportConfiguration } from '../DetailsPanelSportConfigurationFactory'
import {
  SoccerAttributesConfiguration,
  SoccerDetailsConfiguration,
  SoccerGroupName,
} from './SoccerDetailsConfiguration'
import {
  SoccerDetailsIncidentIds,
  SoccerHideIncidentIds,
  SoccerParticipantIncidentIds,
  SoccerSecondParticipantIncidentIds,
  SoccerSelectedOnStartDetailsIncidentIds,
} from './SoccerFiltersIncidentIds'
import { SoccerManualAddIncidentIds } from './SoccerManualAddIncidentIds'
import { SoccerManualAddStatusIds } from './SoccerManualAddStatusIds'

export const SoccerConfiguration: DetailsPanelSportConfiguration<
  SoccerIncidentId,
  SoccerAttributeId,
  SoccerEventStatusId
> = {
  groupNames: SoccerGroupName,
  detailsConfiguration: SoccerDetailsConfiguration,
  attributesConfiguration: SoccerAttributesConfiguration,
  secondParticipantIncidents: SoccerSecondParticipantIncidentIds,
  filters: {
    hideIncidents: SoccerHideIncidentIds,
    participantIncidents: SoccerParticipantIncidentIds,
    detailsIncidents: SoccerDetailsIncidentIds,
    selectedOnStartDetailsIncidents: SoccerSelectedOnStartDetailsIncidentIds,
  },
  manualAddIncidentModal: {
    statuses: SoccerManualAddStatusIds,
    incidents: SoccerManualAddIncidentIds,
    substitutionIncidents: [
      SoccerIncidentId.SubstitutionIn,
      SoccerIncidentId.SubstitutionOut,
    ],
  },
}
