<script setup lang="ts">
import { computed } from 'vue'

import { IncidentActionType } from '@collector/sportsapi-client-legacy'
import Button from '@mobile/components/Button/Button.vue'

import { ProbableLinkedIncident } from '../../Shared/RelationDependencies/types'

interface Props {
  linkedIncident: ProbableLinkedIncident
}

const props = defineProps<Props>()

// computed
const isDeleted = computed(
  () => props.linkedIncident.incident.action === IncidentActionType.DELETE,
)
</script>

<template>
  <Button
    class="px-3"
    color="yellow"
    size="xs"
    :disabled="isDeleted"
  >
    <FontAwesomeIcon icon="exchange-alt" />
  </Button>
</template>
