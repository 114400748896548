import {
  BasketballAttributeId,
  BasketballIncidentId,
  BasketballThreePointShotMadeAttribute,
  BasketballThreePointShotMissedAttribute,
  BasketballTwoPointShotMadeAttribute,
  BasketballTwoPointShotMissedAttribute,
  DetailType,
} from '@collector/sportsapi-client-legacy'
import {
  SportAttributesConfiguration,
  SportDetailsConfiguration,
} from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'

export enum BasketballGroupNames {
  ShootType = 'Shoot type',
}

export const BasketballDetailsConfiguration: SportDetailsConfiguration<
  BasketballIncidentId,
  BasketballAttributeId
> = {
  [BasketballIncidentId.TwoPointShotMade]: [
    {
      name: BasketballGroupNames.ShootType,
      attributes: [
        BasketballTwoPointShotMadeAttribute.JumpShot,
        BasketballTwoPointShotMadeAttribute.Layup,
        BasketballTwoPointShotMadeAttribute.Dunk,
        BasketballTwoPointShotMadeAttribute.Hook,
      ],
      cols: 4,
      important: true,
      type: DetailType.Button,
    },
  ],
  [BasketballIncidentId.TwoPointShotMissed]: [
    {
      name: BasketballGroupNames.ShootType,
      attributes: [
        BasketballTwoPointShotMissedAttribute.JumpShot,
        BasketballTwoPointShotMissedAttribute.Layup,
        BasketballTwoPointShotMissedAttribute.Dunk,
        BasketballTwoPointShotMissedAttribute.Hook,
      ],
      cols: 4,
      important: true,
      type: DetailType.Button,
    },
  ],
  [BasketballIncidentId.ThreePointShotMade]: [
    {
      name: BasketballGroupNames.ShootType,
      attributes: [BasketballThreePointShotMadeAttribute.JumpShot],
      cols: 1,
      important: true,
      type: DetailType.Button,
    },
  ],
  [BasketballIncidentId.ThreePointShotMissed]: [
    {
      name: BasketballGroupNames.ShootType,
      attributes: [BasketballThreePointShotMissedAttribute.JumpShot],
      cols: 1,
      important: true,
      type: DetailType.Button,
    },
  ],
}
export const BasketballAttributesConfiguration: SportAttributesConfiguration<
  BasketballIncidentId,
  BasketballAttributeId
> = {
  [BasketballIncidentId.TwoPointShotMade]: [
    BasketballTwoPointShotMadeAttribute.JumpShot,
    BasketballTwoPointShotMadeAttribute.Layup,
    BasketballTwoPointShotMadeAttribute.Dunk,
    BasketballTwoPointShotMadeAttribute.Hook,
  ],
  [BasketballIncidentId.TwoPointShotMissed]: [
    BasketballTwoPointShotMissedAttribute.JumpShot,
    BasketballTwoPointShotMissedAttribute.Layup,
    BasketballTwoPointShotMissedAttribute.Dunk,
    BasketballTwoPointShotMissedAttribute.Hook,
  ],
  [BasketballIncidentId.ThreePointShotMade]: [
    BasketballThreePointShotMadeAttribute.JumpShot,
  ],
  [BasketballIncidentId.ThreePointShotMissed]: [
    BasketballThreePointShotMissedAttribute.JumpShot,
  ],
}
