import { Ref, ref } from 'vue'

import {
  ApiClient,
  isWretchError,
  SportId,
} from '@collector/sportsapi-client-legacy'
import { AppDatabase } from '@mobile/database/AppDatabase'
import * as app from '@mobile/globalState/app'

// @TODO Handle errors + compatibility with offline mode, save in vuex, synchronize with IndexedDB
export function useSportsSynchronizer(
  apiClient: ApiClient,
  database: AppDatabase,
): {
  sportsSynchronized: Ref<boolean>
  synchronizeSports: () => Promise<void>
  reloadSports: () => Promise<void>
} {
  const sportsSynchronized = ref(false)
  const sportIds: SportId[] = [
    SportId.Soccer,
    SportId.Handball,
    SportId.Cricket,
    SportId.Volleyball,
    SportId.Basketball,
    SportId.BeachVolleyball,
    SportId.AmericanFootball,
    SportId.Basketball3x3,
    SportId.IceHockey,
  ]

  async function reloadSports(): Promise<void> {
    for (const sportId of sportIds) {
      const sport = await database.sports.getBySportId(sportId)

      if (sport) {
        app.putSport(sport)
      }
    }
  }

  async function synchronizeSports(): Promise<void> {
    try {
      const responses = await Promise.all(
        sportIds.map((id) => apiClient.getSport(id)),
      )

      for (const response of responses) {
        const sport = response.api.data.sport

        await database.sports.put(sport)
        app.putSport(sport)
      }
    } catch (error) {
      if (isWretchError(error) && error.status !== 401) {
        /* eslint-disable-next-line no-console */
        console.error(error)
      }

      reloadSports()
    } finally {
      sportsSynchronized.value = true
    }
  }

  return { sportsSynchronized, synchronizeSports, reloadSports }
}
