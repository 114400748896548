import { onBeforeUnmount, ref, Ref } from 'vue'

const isPortrait = ref(isScreenOrientationPortrait())

function isScreenOrientationPortrait(): boolean {
  return screen.orientation.type.includes('portrait')
}

function handleOrientationChange(): void {
  isPortrait.value = isScreenOrientationPortrait()
}

export function useDeviceOrientation(): {
  isPortrait: Ref<boolean>
  init: () => void
} {
  function init(): void {
    window.addEventListener('orientationchange', handleOrientationChange)

    onBeforeUnmount(() =>
      window.removeEventListener('orientationchange', handleOrientationChange),
    )
  }

  return { isPortrait, init }
}
