import { StatusCodes } from 'http-status-codes'
import wretch, { Wretch, WretchResponse } from 'wretch'
import QueryString, { QueryStringAddon } from 'wretch/addons/queryString'

import { Event } from '../types/basic/Event'
import { FeedRequestDTO } from '../types/dtos/FeedRequestDTO'
import {
  IncidentsQueuePatchDTO,
  IncidentsQueuePatchResponse,
  IncidentsQueuePostDTO,
  IncidentsQueuePostResponse,
} from '../types/dtos/IncidentsQueueDTO'
import { ParticipantsRequestDTO } from '../types/dtos/ParticipantsRequestDTO'
import { UpdateEventDTO } from '../types/dtos/UpdateEventDTO'
import { UpdateEventParticipantsDTO } from '../types/dtos/UpdateEventParticipantsDTO'
import { IncidentId } from '../types/enums'
import { SwappableIncidentsShowResponse } from '../types/responses'
import { ApiResponse } from '../types/responses/ApiResponse'
import { EventScoutsShowResponse } from '../types/responses/event/EventScoutsShowResponse'
import { EventShowResponse } from '../types/responses/event/EventShowResponse'
import { EventUpdateResponse } from '../types/responses/event/EventUpdateResponse'
import { FeedResponse } from '../types/responses/feed/FeedResponse'
import { LineupsShowResponse } from '../types/responses/lineups/LineupsShowResponse'
import { LineupUpdateResponse } from '../types/responses/lineups/LineupUpdateResponse'
import { ParticipantsResponse } from '../types/responses/participants/ParticipantsResponse'
import { SeasonParticipantsShowResponse } from '../types/responses/seasonParticipants/SeasonParticipantsShowResponse'
import { ParticipantSkinsShowResponse } from '../types/responses/skins/ParticipantSkinsShowResponse'
import { SportShowResponse } from '../types/responses/sport/SportShowResponse'
import { UsersShowResponse } from '../types/responses/users/UserShowResponse'
import {
  SocketIncidentResponse,
  WebsocketMessageConfirmator,
} from '../websocket/WebsocketMessageConfirmator'
import { Authorization } from './Authorization'

export class ApiClient {
  private client: QueryStringAddon & Wretch<QueryStringAddon>
  private messageConfirmator!: WebsocketMessageConfirmator

  constructor(
    apiUrl: string,
    private readonly authorization: Authorization,
    wretchPolyfills?: {
      fetch: unknown
      formData: unknown
      URLSearchParams: unknown
    },
  ) {
    this.client = wretch().addon(QueryString)

    if (wretchPolyfills) {
      this.client = this.client.polyfills(wretchPolyfills)
    }

    this.client = this.client.url(apiUrl)
    this.client = this.client.middlewares([
      (next) =>
        async (url, options): Promise<WretchResponse> => {
          return next(url, {
            ...options,
            headers: {
              ...(options.headers || {}),
              'x-statscore-app': 'collector',
              ...{ Authorization: `Bearer ${this.authorization.currentJWT}` },
            },
          })
        },
      (next) =>
        async (url, options): Promise<WretchResponse> => {
          const response = await next(url, options)
          if (
            response.status === StatusCodes.UNAUTHORIZED ||
            (response.status === StatusCodes.BAD_REQUEST &&
              (await response.clone().text()).includes(
                'Could not decode token',
              ))
          ) {
            if (url.includes('auth')) {
              return response
            }
            const token = await this.reauthorize()

            if (!token) {
              return response
            }

            return next(url, {
              ...options,
              headers: {
                ...(options['headers'] || {}),
                ...{ Authorization: `Bearer ${token}` },
              },
            })
          }

          return response
        },
    ])
  }

  public async initAuthorization(apiUrl: string): Promise<void> {
    this.authorization.onNewToken((token) => {
      this.client = this.client.headers({ Authorization: `Bearer ${token}` })
    }, true)

    await this.authorization.init(wretch().url(apiUrl))
  }

  public async reauthorize(): Promise<string | undefined> {
    return this.authorization.reauthorize()
  }

  // @TODO - add return type
  public async updateEventParticipants(
    eventId: number,
    updateEventParticipantsDTO: UpdateEventParticipantsDTO,
  ): Promise<EventUpdateResponse> {
    return this.client
      .url(`/events/${eventId}/participants`)
      .patch(updateEventParticipantsDTO)
      .json()
  }

  public async updateEvent(
    eventId: number,
    updateEventDTO: UpdateEventDTO,
  ): Promise<EventUpdateResponse> {
    return this.client.url(`/events/${eventId}`).patch(updateEventDTO).json()
  }

  public async getEvent(eventId: number): Promise<Event> {
    const response: EventShowResponse = await this.client
      .url(`/events/${eventId}`)
      .get()
      .json()

    const event = response.api.data.competition.season.stage.group.event

    // TODO: remove after SportsAPI Team update
    event.season_id = event.season_id ?? response.api.data.competition.season.id

    return event
  }

  // @TODO - add return type
  public async getEventFeed(): Promise<unknown> {
    return this.client.url('/feed').get().json()
  }

  public setMessageConfirmator(
    messageConfirmator: WebsocketMessageConfirmator,
  ): void {
    this.messageConfirmator = messageConfirmator
  }

  public async postIncident(
    eventId: number,
    incident: IncidentsQueuePostDTO,
    uuid: string,
  ): Promise<SocketIncidentResponse> {
    const response = await this.client
      .url(`/events/${eventId}/incidents-queue`)
      .post({
        incident: {
          ...incident,
          attribute_id: incident.attribute_ids,
        },
        uuid,
      })
      .json<IncidentsQueuePostResponse>()

    const jobId = response.api.data.uuid

    return new Promise<SocketIncidentResponse>((resolve, reject) => {
      this.messageConfirmator.onUuid(
        jobId,
        (data) => resolve(data),
        (error) => reject(error),
        true,
      )
    })
  }

  public async patchIncident(
    eventId: number,
    incidentId: number,
    incident: IncidentsQueuePatchDTO,
  ): Promise<SocketIncidentResponse> {
    const response = await this.client
      .url(`/events/${eventId}/incidents-queue/${incidentId}`)
      .patch({
        incident: {
          ...incident,
          id: incidentId,
          attribute_id: incident.attribute_ids,
          action: 'update',
        },
      })
      .json<IncidentsQueuePatchResponse>()
    const jobId = response.api.data.uuid

    return new Promise<SocketIncidentResponse>((resolve, reject) => {
      this.messageConfirmator.onUuid(
        jobId,
        (data) => resolve(data),
        (error) => reject(error),
        true,
      )
    })
  }

  public async swapIncident(
    eventId: number,
    eventIncidentId: number,
    incidentId: IncidentId,
    teamId: number,
  ): Promise<unknown> {
    return this.client
      .url(`/events/${eventId}/incidents/swap`)
      .post({
        event_incident_id: eventIncidentId,
        incident_id: incidentId,
        team_id: teamId,
      })
      .json<IncidentsQueuePatchResponse>()
  }

  public async deleteIncident(
    eventId: number,
    incidentId: number,
  ): Promise<SocketIncidentResponse> {
    const response = await this.client
      .url(`/events/${eventId}/incidents-queue/${incidentId}`)
      .delete()
      .json<ApiResponse<{ uuid: string }>>()
    const jobId = response.api.data.uuid

    return new Promise<SocketIncidentResponse>((resolve, reject) => {
      this.messageConfirmator.onUuid(
        jobId,
        (data) => resolve(data),
        (error) => reject(error),
        true,
      )
    })
  }

  public getSport(sportId: number): Promise<SportShowResponse> {
    return this.client.url(`/sports/${sportId}`).get().json()
  }

  public async getParticipantSkins(
    participantIds: number[],
  ): Promise<ParticipantSkinsShowResponse> {
    const response: ParticipantSkinsShowResponse = await this.client
      .url('/skins')
      .query({ participants_ids: participantIds.join(',') })
      .get()
      .json()

    if (Array.isArray(response.api.data)) {
      response.api.data = { skins: [] }
    }

    return response
  }

  public getEventSwappableIncidents(
    eventId: number,
  ): Promise<SwappableIncidentsShowResponse> {
    return this.client
      .url(`/events/${eventId}/swappable-incidents`)
      .get()
      .json()
  }

  public async getSeasonParticipants(
    participantId: number,
    seasonId: number,
  ): Promise<SeasonParticipantsShowResponse> {
    const response: SeasonParticipantsShowResponse = await this.client
      .url(`/participants/${participantId}/squad`)
      .query({ season_id: seasonId })
      .get()
      .json()

    if (Array.isArray(response.api.data)) {
      response.api.data = { participants: [] }
    }

    return response
  }

  public async getEventLineups(eventId: number): Promise<LineupsShowResponse> {
    const response: LineupsShowResponse = await this.client
      .url(`/events/${eventId}/sub-participants`)
      .get()
      .json()

    if (Array.isArray(response.api.data)) {
      response.api.data = { sub_participants: [] }
    }

    return response
  }

  public async getFeed(data: FeedRequestDTO): Promise<FeedResponse> {
    return await this.client.url('/feed').query(data).get().json<FeedResponse>()
  }

  public async getParticipants(
    queryOptions: ParticipantsRequestDTO,
  ): Promise<ParticipantsResponse> {
    const response = await this.client
      .url('/participants')
      .query(queryOptions)
      .get()
      .json<ParticipantsResponse>()

    if (!response.api.data.participants) {
      response.api.data.participants = []
    }

    return response
  }

  public async patchLineups(
    eventId: number,
    teamId: number,
    squad: unknown[],
  ): Promise<LineupUpdateResponse> {
    return this.client
      .url(`/events/${eventId}/sub-participants-save`)
      .post({
        team_id: teamId,
        squad,
      })
      .json<LineupUpdateResponse>()
  }

  public async getUser(userId: number): Promise<UsersShowResponse> {
    const response = await this.client
      .url(`/users/${userId}`)
      .get()
      .json<UsersShowResponse>()

    return response
  }

  public async getEventScouts(
    eventId: number,
  ): Promise<EventScoutsShowResponse> {
    const response: EventScoutsShowResponse = await this.client
      .url(`/events/${eventId}/scouts`)
      .get()
      .json()

    return response
  }
}
