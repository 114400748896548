import { jwtDecode, JwtPayload } from 'jwt-decode'

import Sentry from '@collector/shared-plugin-sentry-vue'
import * as localStorage from '@mobile/globalState/localStorage'
import * as sportsAPI from '@mobile/globalState/sportsAPI'
import router from '@mobile/router'

let logoutTimeout = 0

export function useLogout(): {
  logout: () => void
  scheduleLogout: () => void
} {
  function logout(): void {
    sportsAPI.logout()
    Sentry.setUser(null)
    localStorage.removeItem(localStorage.ItemKey.AuthInfo)
    localStorage.removeItem(localStorage.ItemKey.SportsAPIJWT)
    localStorage.removeItem(localStorage.ItemKey.SportsApiRefreshToken)
    router.push({ name: 'EventsList' })
  }

  function scheduleLogout(): void {
    const refreshToken = localStorage.getSportsApiRefreshToken()
    if (!refreshToken) {
      return
    }

    const { exp } = jwtDecode<JwtPayload>(refreshToken)

    if (!exp) {
      return
    }

    const time = exp * 1000 - new Date().getTime()
    window.clearTimeout(logoutTimeout)

    // https://developer.mozilla.org/en-US/docs/Web/API/Window/setTimeout#maximum_delay_value
    logoutTimeout = window.setTimeout(logout, Math.min(time, 2147483647))
  }

  return {
    logout,
    scheduleLogout,
  }
}
