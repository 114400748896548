export enum IceHockeyGoalAttribute {
  PenaltyGoal = 491,
  PowerPlay = 394,
  Shorthanded = 395,
  EmptyNet = 396,
  EvenStrength = 797,
  HighDangerRangeGoal = 798,
  MidRangeGoal = 799,
  LongRangeGoal = 800,
  BehindTheNet = 801,
  BehindTheBlueLine = 802,
  UpperLeftGoals = 803,
  UpperRightGoals = 804,
  UpperMiddleGoals = 805,
  BottomMiddleGoals = 806,
  BottomRightGoals = 807,
  BottomLeftGoals = 808,
}
