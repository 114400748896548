import {
  CommonIncidentId,
  SoccerIncidentId,
} from '@collector/sportsapi-client-legacy'

export const SoccerHideIncidentIds: (SoccerIncidentId | CommonIncidentId)[] = [
  SoccerIncidentId.StartDelayed,
  SoccerIncidentId.MatchAboutToStart,
  SoccerIncidentId.FirstHalf,
  SoccerIncidentId.Halftime,
  SoccerIncidentId.SecondHalf,
  SoccerIncidentId.WaitingForExtratime,
  SoccerIncidentId.ExtratimeFirstHalf,
  SoccerIncidentId.ExtratimeHalftime,
  SoccerIncidentId.ExtratimeSecondHalf,
  SoccerIncidentId.WaitingForExtratime,
  SoccerIncidentId.PenaltyShootoutStarted,
  SoccerIncidentId.FinishedRegularTime,
  SoccerIncidentId.FinishedAfterExtratime,
  SoccerIncidentId.FinishedAfterPenalties,
  SoccerIncidentId.Interrupted,
  CommonIncidentId.TechnicalProblems,
  SoccerIncidentId.Goal,
  SoccerIncidentId.Penalty,
  SoccerIncidentId.MissedPenalty,
  SoccerIncidentId.PenaltySavedByGoalkeeper,
  SoccerIncidentId.YellowCard,
  SoccerIncidentId.RedCard,
  SoccerIncidentId.Corner,
  CommonIncidentId.Betstart,
  CommonIncidentId.Betstop,
  SoccerIncidentId.BookingMarketsDisabled,
  SoccerIncidentId.BookingMarketsEnabled,
  SoccerIncidentId.CornerMarketsDisabled,
  SoccerIncidentId.CornerMarketsEnabled,
  SoccerIncidentId.VideoAssistantReferee,
  SoccerIncidentId.VarEnded,
  SoccerIncidentId.AddedTime,
  SoccerIncidentId.KickOff,
  SoccerIncidentId.SubstitutionOut,
  SoccerIncidentId.SubstitutionIn,
  SoccerIncidentId.ShotOnTarget,
  SoccerIncidentId.ShotOffTarget,
  SoccerIncidentId.ShotBlocked,
  SoccerIncidentId.Offside,
  SoccerIncidentId.Foul,
  SoccerIncidentId.FreeKick,
  SoccerIncidentId.DangerousFreeKick,
  SoccerIncidentId.ThrowIn,
  SoccerIncidentId.GoalKick,
  SoccerIncidentId.Injury,
  SoccerIncidentId.InPossession,
  SoccerIncidentId.Attack,
  SoccerIncidentId.DangerousAttack,
  SoccerIncidentId.WaterBreak,
  SoccerIncidentId.RefereesInjury,
  SoccerIncidentId.BreakDuringTheGame,
  SoccerIncidentId.Tackle,
  SoccerIncidentId.Interception,
  SoccerIncidentId.BallSkills,
  SoccerIncidentId.LooseBall,
]

export const SoccerParticipantIncidentIds: SoccerIncidentId[] = [
  SoccerIncidentId.Goal,
  SoccerIncidentId.Penalty,
  SoccerIncidentId.MissedPenalty,
  SoccerIncidentId.PenaltySavedByGoalkeeper,
  SoccerIncidentId.YellowCard,
  SoccerIncidentId.RedCard,
  SoccerIncidentId.SubstitutionIn,
  SoccerIncidentId.SubstitutionOut,
  SoccerIncidentId.ShotOnTarget,
  SoccerIncidentId.ShotOffTarget,
  SoccerIncidentId.ShotBlocked,
  SoccerIncidentId.Offside,
  SoccerIncidentId.Foul,
  SoccerIncidentId.Injury,
  SoccerIncidentId.Tackle,
  SoccerIncidentId.Interception,
  SoccerIncidentId.BallSkills,
  SoccerIncidentId.LooseBall,
]

export const SoccerSecondParticipantIncidentIds = []

export const SoccerDetailsIncidentIds: SoccerIncidentId[] = [
  SoccerIncidentId.Goal,
  SoccerIncidentId.MissedPenalty,
  SoccerIncidentId.PenaltySavedByGoalkeeper,
  SoccerIncidentId.ShotOnTarget,
  SoccerIncidentId.ShotOffTarget,
  SoccerIncidentId.ShotBlocked,
  SoccerIncidentId.Attack,
  SoccerIncidentId.DangerousAttack,
  SoccerIncidentId.InPossession,
]

export const SoccerSelectedOnStartDetailsIncidentIds = [
  SoccerIncidentId.Goal,
  SoccerIncidentId.MissedPenalty,
  SoccerIncidentId.PenaltySavedByGoalkeeper,
  SoccerIncidentId.ShotOnTarget,
  SoccerIncidentId.ShotOffTarget,
  SoccerIncidentId.ShotBlocked,
]
