export enum IceHockeySuspensionAttribute {
  ThreePlayers = 492,
  FourPlayers = 493,
  FivePlayers = 494,
  GameMisconductTwentyMinutes = 495,
  MatchTwentyFiveMinutes = 496,
  MinorTwoMinutes = 497,
  MinorFiveMinutes = 498,
  MisconductTenMinutes = 499,
  Boarding = 818,
  Interference = 819,
  Elbowing = 820,
  Hooking = 821,
  Holding = 822,
  HoldingTheStick = 823,
  HighSticking = 824,
  AbuseOfOfficials = 825,
  CrossChecking = 826,
  Roughing = 827,
  Charging = 828,
  UnsportsmanlikeConduct = 829,
  TooManyMen = 830,
  Fighting = 831,
  DelayOfGame = 832,
  Other = 833,
}
