<template>
  <div class="absolute inset-0 grid h-full w-full grid-cols-2 grid-rows-3">
    <div class="stadium col-start-2 row-span-2">
      <div
        class="track transform portrait:translate-x-1/2 portrait:translate-y-1/2 landscape:translate-x-1/2 landscape:translate-y-1/3"
      >
        <div
          class="lane lane1 border-transparentGray border-8 border-solid"
        ></div>
        <div
          class="lane lane2 border-transparentGray border-8 border-solid"
        ></div>
        <div
          class="lane lane3 border-transparentGray border-8 border-solid"
        ></div>
        <div
          class="lane lane4 border-transparentGray border-8 border-solid"
        ></div>
        <div
          class="lane lane5 border-transparentGray border-8 border-solid"
        ></div>
      </div>
    </div>
    <div class="stadium row-span-2 row-start-2 justify-self-end">
      <div
        class="track landscape-xs:translate-y-1/4 transform portrait:translate-x-1/2 portrait:translate-y-1/2 portrait:md:translate-y-3/4 landscape:translate-x-1/2 landscape:translate-y-1/2"
      >
        <div
          class="lane lane1 border-transparentGray border-2 border-solid"
        ></div>
        <div
          class="lane lane2 border-transparentGray border-2 border-solid"
        ></div>
        <div
          class="lane lane3 border-transparentGray border-2 border-solid"
        ></div>
        <div
          class="lane lane4 border-transparentGray border-2 border-solid"
        ></div>
        <div
          class="lane lane5 border-transparentGray border-2 border-solid"
        ></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stadium {
  overflow: hidden;
  width: 980px;
  height: 620px;
}

.track {
  width: 100%;
  height: 100%;
  border-radius: 200px;
}

.lane {
  position: absolute;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  border-radius: 200px;
}

.lane1 {
  width: 97%;
  height: 69%;
}

.lane2 {
  width: 91%;
  height: 60%;
}

.lane3 {
  width: 84%;
  height: 49%;
}

.lane4 {
  width: 77%;
  height: 39%;
}

.lane5 {
  width: 70%;
  height: 29%;
}
</style>
