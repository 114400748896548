import { computed, ComputedRef, Ref } from 'vue'

import {
  EventStatusId,
  IncidentAttributeId,
  IncidentId,
  Property,
  SportId,
  SportIncident,
  SportStatus,
} from '@collector/sportsapi-client-legacy'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'
import { BasketballConfiguration } from '@mobile/views/Relation/DetailsPanel/Sports/Basketball/BasketballConfiguration'
import {
  DetailInputConfiguration,
  ListOption,
  SportAttributesConfiguration,
  SportDetailsConfiguration,
} from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'
import { SoccerConfiguration } from '@mobile/views/Relation/DetailsPanel/Sports/Soccer/SoccerConfiguration'

import { getDetailsPanelSportConfiguration } from '../../DetailsPanelSportConfigurationFactory'

export const GroupNames = {
  ...SoccerConfiguration.groupNames,
  ...BasketballConfiguration.groupNames,
}

export type SportOptionsComposable = ReturnType<typeof useSportOptions>

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
export function useSportOptions(sport: ComputedRef<CollectorSport>) {
  const hideIncidentsOptions: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getHideIncidentsFilterValues,
      sport.value.incidents,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const participantIncidentsOptions: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getParticipantIncidentsFilterValues,
      sport.value.incidents,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const detailsIncidentsOptions: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getDetailsIncidentsFilterValues,
      sport.value.incidents,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const manualAddIncidents: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getManualAddIncidents,
      sport.value.incidents,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const manualAddSubstitutionIncidents: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getManualAddSubstitutionIncidents,
      sport.value.incidents,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const eventStatuses: Ref<ListOption[]> = computed(() =>
    mapIncidents(
      getManualAddIncidentModalStatuses,
      sport.value.statuses,
      sport.value.sportsAPIEntity.id,
    ),
  )

  const secondParticipantIncidents = getSecondParticipantIncidents(
    sport.value.sportsAPIEntity.id,
  )

  const detailsConfiguration: SportDetailsConfiguration<
    IncidentId,
    IncidentAttributeId
  > = getDetailsConfiguration(sport.value.sportsAPIEntity.id)

  const attributesConfiguration = getAttributesConfiguration(
    sport.value.sportsAPIEntity.id,
  )

  const selectedOnStartDetailsIncidents = getSelectedOnStartDetailsIncidents(
    sport.value.sportsAPIEntity.id,
  )

  return {
    hideIncidentsOptions,
    participantIncidentsOptions,
    detailsIncidentsOptions,
    manualAddIncidents,
    eventStatuses,
    detailsConfiguration,
    manualAddSubstitutionIncidents,
    attributesConfiguration,
    secondParticipantIncidents,
    selectedOnStartDetailsIncidents,
  }
}

export function mapIncidents(
  fn: (sportId: SportId) => IncidentId[] | EventStatusId[],
  list: { [key: number]: SportIncident | SportStatus | undefined },
  sportId: number,
): {
  name: string
  value: string | number
}[] {
  const incidentsList = fn(sportId)

  return Object.values(list)
    .filter((incident) =>
      incidentsList.some((id) => Number(id) === incident?.id),
    )
    .map((incident) => {
      return {
        name: incident?.name ?? '',
        value: incident?.id ?? '',
      }
    })
    .sort(
      (a, b) =>
        incidentsList.indexOf(a?.value as never) -
        incidentsList.indexOf(b?.value as never),
    )
}

function getHideIncidentsFilterValues(sportId: SportId): IncidentId[] {
  return getDetailsPanelSportConfiguration(sportId).filters.hideIncidents
}

function getParticipantIncidentsFilterValues(sportId: SportId): IncidentId[] {
  return getDetailsPanelSportConfiguration(sportId).filters.participantIncidents
}

function getSecondParticipantIncidents(sportId: SportId): IncidentId[] {
  return (getDetailsPanelSportConfiguration(sportId)
    ?.secondParticipantIncidents ?? []) as IncidentId[]
}

function getDetailsIncidentsFilterValues(sportId: SportId): IncidentId[] {
  return getDetailsPanelSportConfiguration(sportId).filters.detailsIncidents
}

function getSelectedOnStartDetailsIncidents(sportId: SportId): IncidentId[] {
  return (getDetailsPanelSportConfiguration(sportId).filters
    .selectedOnStartDetailsIncidents ?? []) as IncidentId[]
}

function getManualAddIncidents(sportId: SportId): IncidentId[] {
  return (getDetailsPanelSportConfiguration(sportId).manualAddIncidentModal
    .incidents ?? []) as IncidentId[]
}

function getManualAddSubstitutionIncidents(sportId: SportId): IncidentId[] {
  return (getDetailsPanelSportConfiguration(sportId).manualAddIncidentModal
    .substitutionIncidents ?? []) as IncidentId[]
}

function getManualAddIncidentModalStatuses(sportId: SportId): EventStatusId[] {
  return getDetailsPanelSportConfiguration(sportId).manualAddIncidentModal
    .statuses
}

function getDetailsConfiguration(
  sportId: SportId,
): SportDetailsConfiguration<IncidentId, IncidentAttributeId> {
  return getDetailsPanelSportConfiguration(sportId).detailsConfiguration
}

function getAttributesConfiguration(
  sportId: SportId,
): SportAttributesConfiguration<IncidentId, IncidentAttributeId> {
  return getDetailsPanelSportConfiguration(sportId).attributesConfiguration
}

export function getPropertyIndex(
  properties: Property[] | undefined,
  propertyName: string | undefined,
): number {
  const index = properties?.findIndex(
    (property) =>
      property.short_name.toLowerCase() === propertyName?.toLowerCase(),
  )

  return !index || index === -1 ? 0 : index
}

export function enforceMinMax(
  property: Property[] | undefined,
  properties: Property[] | undefined,
  config: DetailInputConfiguration<IncidentAttributeId>,
): void {
  if (property) {
    const selectedPropertyIndex = getPropertyIndex(properties, config.name)

    if (property[selectedPropertyIndex].value === '') {
      return
    }

    if (
      Number(property[selectedPropertyIndex].value) ||
      Number(property[selectedPropertyIndex].value) === 0
    ) {
      if (config.min !== undefined) {
        property[selectedPropertyIndex].value = Math.max(
          Number(property[selectedPropertyIndex].value),
          config.min,
        )
      }

      if (config.max !== undefined) {
        property[selectedPropertyIndex].value = Math.min(
          Number(property[selectedPropertyIndex].value),
          config.max,
        )
      }
    } else {
      property[selectedPropertyIndex].value = null
    }
  }
}
