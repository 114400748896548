import { get } from 'lodash'

import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

export function isSelectFulfilled(
  key: 'secondary' | 'ternary',
  incidentKey: 'assistant_id' | 'secondary_assistant_id',
  linkedIncident: ProbableLinkedIncident,
): boolean {
  const { assignableIncidentsConfiguration } = useSportConfiguration()

  const assignableIncidentConfiguration = get(
    assignableIncidentsConfiguration,
    linkedIncident.incident.incident_id,
    null,
  )
  const predicate =
    assignableIncidentConfiguration?.participantsConfiguration?.[key]
      ?.fulfilledWhen

  if (predicate) {
    return predicate(linkedIncident.incident)
  }

  return !!(
    linkedIncident.incident[incidentKey] ||
    linkedIncident.incident.additional_info?.[incidentKey]
  )
}
