import {
  AmericanFootballAttributeId,
  AmericanFootballEventStatusId,
  AmericanFootballIncidentId,
} from '@collector/sportsapi-client-legacy'

import { DetailsPanelSportConfiguration } from '../DetailsPanelSportConfigurationFactory'
import {
  AmericanFootballAttributesConfiguration,
  AmericanFootballDetailsConfiguration,
  AmericanFootballGroupNames,
} from './AmericanFootballDetailsConfiguration'
import {
  AmericanFootballDetailsIncidentIds,
  AmericanFootballHideIncidentIds,
  AmericanFootballParticipantIncidentIds,
  AmericanFootballSecondParticipantIncidentIds,
} from './AmericanFootballFiltersIncidentIds'
import { AmericanFootballManualAddIncidentIds } from './AmericanFootballManualAddIncidentIds'
import { AmericanFootballManualAddStatusIds } from './AmericanFootballManualAddStatusIds'

export const AmericanFootballConfiguration: DetailsPanelSportConfiguration<
  AmericanFootballIncidentId,
  AmericanFootballAttributeId,
  AmericanFootballEventStatusId
> = {
  groupNames: AmericanFootballGroupNames,
  detailsConfiguration: AmericanFootballDetailsConfiguration,
  attributesConfiguration: AmericanFootballAttributesConfiguration,
  secondParticipantIncidents: AmericanFootballSecondParticipantIncidentIds,
  filters: {
    hideIncidents: AmericanFootballHideIncidentIds,
    participantIncidents: AmericanFootballParticipantIncidentIds,
    detailsIncidents: AmericanFootballDetailsIncidentIds,
    selectedOnStartDetailsIncidents: AmericanFootballDetailsIncidentIds,
  },
  manualAddIncidentModal: {
    statuses: AmericanFootballManualAddStatusIds,
    incidents: AmericanFootballManualAddIncidentIds,
    substitutionIncidents: [],
  },
}
