import {
  DetailType,
  IceHockeyAttributeIds,
  IceHockeyGoalAttribute,
  IceHockeyIncidentId,
  IceHockeyMissedPenaltyAttribute,
  IceHockeyShotBlockedAttribute,
  IceHockeyShotOnTargetAttribute,
  IceHockeyStoppageAttribute,
  IceHockeySuspensionAttribute,
  IceHockeySuspensionOverAttribute,
} from '@collector/sportsapi-client-legacy'

import { SportDetailsConfiguration } from '../composables/useSportOptions/types'

const SuspensionTypeAttributeIds = [
  IceHockeySuspensionAttribute.Boarding,
  IceHockeySuspensionAttribute.Interference,
  IceHockeySuspensionAttribute.Elbowing,
  IceHockeySuspensionAttribute.Hooking,
  IceHockeySuspensionAttribute.Holding,
  IceHockeySuspensionAttribute.HoldingTheStick,
  IceHockeySuspensionAttribute.HighSticking,
  IceHockeySuspensionAttribute.AbuseOfOfficials,
  IceHockeySuspensionAttribute.CrossChecking,
  IceHockeySuspensionAttribute.Roughing,
  IceHockeySuspensionAttribute.Charging,
  IceHockeySuspensionAttribute.UnsportsmanlikeConduct,
  IceHockeySuspensionAttribute.TooManyMen,
  IceHockeySuspensionAttribute.Fighting,
  IceHockeySuspensionAttribute.DelayOfGame,
  IceHockeySuspensionAttribute.Other,
]

export enum IceHockeyGroupNames {
  NumberOfPlayers = 'Number of players',
  SuspensionLength = 'Suspension length',
  SuspensionType = 'Suspension type',
  GoalType = 'Goal type',
  Distance = 'Distance',
  Placement = 'Placement',
  Goalkeeper = 'Goalkeeper',
  StoppageType = 'Stoppage type',
  ShotOnTargetType = 'Shot on target type',
  MissedPenaltyType = 'Missed penalty type',
}

export const IceHockeyDetailsConfiguration: SportDetailsConfiguration<
  IceHockeyIncidentId,
  IceHockeyAttributeIds
> = {
  [IceHockeyIncidentId.Suspension]: [
    {
      name: IceHockeyGroupNames.NumberOfPlayers,
      attributes: [
        IceHockeySuspensionAttribute.ThreePlayers,
        IceHockeySuspensionAttribute.FourPlayers,
        IceHockeySuspensionAttribute.FivePlayers,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
    {
      name: IceHockeyGroupNames.SuspensionLength,
      attributes: [
        IceHockeySuspensionAttribute.MinorTwoMinutes,
        IceHockeySuspensionAttribute.MinorFiveMinutes,
        IceHockeySuspensionAttribute.MisconductTenMinutes,
        IceHockeySuspensionAttribute.GameMisconductTwentyMinutes,
        IceHockeySuspensionAttribute.MatchTwentyFiveMinutes,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
    {
      name: IceHockeyGroupNames.SuspensionType,
      optionsFactory: (likedIncident) => {
        return SuspensionTypeAttributeIds.map((attributeId) => {
          const attribute = likedIncident.sportIncident.attributes.find(
            (a) => a.id === attributeId,
          )
          const name = attribute?.name || `Ice Hockey attribute ${attributeId}`

          return { name, value: attributeId }
        })
      },
      attributes: SuspensionTypeAttributeIds,
      important: true,
      type: DetailType.Select,
    },
  ],
  [IceHockeyIncidentId.SuspensionOver]: [
    {
      name: IceHockeyGroupNames.NumberOfPlayers,
      attributes: [
        IceHockeySuspensionOverAttribute.ThreePlayers,
        IceHockeySuspensionOverAttribute.FourPlayers,
        IceHockeySuspensionOverAttribute.FivePlayers,
        IceHockeySuspensionOverAttribute.NoPowerPlay,
      ],
      cols: 4,
      important: true,
      multiple: true,
      type: DetailType.Button,
    },
  ],
  [IceHockeyIncidentId.Goal]: [
    {
      name: IceHockeyGroupNames.GoalType,
      attributes: [
        IceHockeyGoalAttribute.PenaltyGoal,
        IceHockeyGoalAttribute.PowerPlay,
        IceHockeyGoalAttribute.Shorthanded,
        IceHockeyGoalAttribute.EmptyNet,
        IceHockeyGoalAttribute.EvenStrength,
      ],
      cols: 3,
      important: true,
      multiple: true,
      type: DetailType.Button,
    },
    {
      name: IceHockeyGroupNames.Distance,
      attributes: [
        IceHockeyGoalAttribute.HighDangerRangeGoal,
        IceHockeyGoalAttribute.MidRangeGoal,
        IceHockeyGoalAttribute.LongRangeGoal,
        IceHockeyGoalAttribute.BehindTheNet,
        IceHockeyGoalAttribute.BehindTheBlueLine,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
    {
      name: IceHockeyGroupNames.Placement,
      attributes: [
        IceHockeyGoalAttribute.UpperLeftGoals,
        IceHockeyGoalAttribute.UpperMiddleGoals,
        IceHockeyGoalAttribute.UpperRightGoals,
        IceHockeyGoalAttribute.BottomLeftGoals,
        IceHockeyGoalAttribute.BottomMiddleGoals,
        IceHockeyGoalAttribute.BottomRightGoals,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
    {
      name: IceHockeyGroupNames.Goalkeeper,
      important: true,
      type: DetailType.Button,
      attributes: [],
      fulfilledWhen: (_, probableIncident) => {
        return !!probableIncident?.additional_info?.goalkeeper_id
      },
    },
  ],
  [IceHockeyIncidentId.Stoppage]: [
    {
      name: IceHockeyGroupNames.StoppageType,
      attributes: [
        IceHockeyStoppageAttribute.PuckOut,
        IceHockeyStoppageAttribute.Fight,
        IceHockeyStoppageAttribute.Icing,
        IceHockeyStoppageAttribute.Offside,
        IceHockeyStoppageAttribute.GoalieStopped,
        IceHockeyStoppageAttribute.ShiftedGoal,
        IceHockeyStoppageAttribute.Foul,
        IceHockeyStoppageAttribute.Injury,
        IceHockeyStoppageAttribute.Other,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
  ],
  [IceHockeyIncidentId.ShotOnTarget]: [
    {
      name: IceHockeyGroupNames.ShotOnTargetType,
      attributes: [
        IceHockeyShotOnTargetAttribute.EvenStrength,
        IceHockeyShotOnTargetAttribute.PowerPlay,
        IceHockeyShotOnTargetAttribute.Shorthanded,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
    },
    {
      name: IceHockeyGroupNames.Goalkeeper,
      important: true,
      type: DetailType.Button,
      attributes: [],
      fulfilledWhen: (_, probableIncident) => {
        return !!probableIncident?.additional_info?.goalkeeper_id
      },
    },
  ],
  [IceHockeyIncidentId.MissedPenalty]: [
    {
      name: IceHockeyGroupNames.MissedPenaltyType,
      attributes: [
        IceHockeyMissedPenaltyAttribute.PenaltySaved,
        IceHockeyMissedPenaltyAttribute.EvenStrength,
        IceHockeyMissedPenaltyAttribute.PowerPlay,
        IceHockeyMissedPenaltyAttribute.Shorthanded,
      ],
      cols: 3,
      important: true,
      multiple: true,
      type: DetailType.Button,
    },
    {
      name: IceHockeyGroupNames.Goalkeeper,
      attributes: [],
      important: true,
      type: DetailType.Button,
      fulfilledWhen: (_, probableIncident) => {
        return !!probableIncident?.additional_info?.goalkeeper_id
      },
    },
  ],
}

export const IceHockeyAttributesConfiguration = {
  [IceHockeyIncidentId.ShotBlocked]: [
    IceHockeyShotBlockedAttribute.BlockedByTeammate,
  ],
}
