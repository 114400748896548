import {
  AmericanFootballAttributeId,
  AmericanFootballDefensiveReturnAttribute,
  AmericanFootballIncidentId,
  AmericanFootballPassAttribute,
  AmericanFootballSackAttribute,
  AmericanFootballTouchdownAttribute,
  DetailType,
} from '@collector/sportsapi-client-legacy'
import { ProbableIncident } from '@mobile/ActionQueue/Actions/Incidents/ProbableIncident'
import { SportDetailsConfiguration } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions/types'

export enum AmericanFootballGroupNames {
  Details = 'Details',
  Yards = 'Yards',
}

function hasYardsPropertyFulfilled(
  incident: ProbableIncident | undefined,
): boolean {
  return (
    incident?.properties?.find(
      (x) => x.short_name.toLowerCase() === 'yards' && x.value !== null,
    ) !== undefined
  )
}

export const AmericanFootballDetailsConfiguration: SportDetailsConfiguration<
  AmericanFootballIncidentId,
  AmericanFootballAttributeId
> = {
  [AmericanFootballIncidentId.Touchdown]: [
    {
      name: AmericanFootballGroupNames.Details,
      attributes: [
        AmericanFootballTouchdownAttribute.Passing,
        AmericanFootballTouchdownAttribute.Rushing,
        AmericanFootballTouchdownAttribute.InterceptionReturn,
        AmericanFootballTouchdownAttribute.FumbleRecovery,
        AmericanFootballTouchdownAttribute.PuntReturn,
        AmericanFootballTouchdownAttribute.KickoffReturn,
      ],
      cols: 3,
      important: true,
      type: DetailType.Button,
      fulfilledWhen: (attributes) => attributes.length > 0,
    },
  ],
  [AmericanFootballIncidentId.Sack]: [
    {
      name: AmericanFootballGroupNames.Details,
      attributes: [
        AmericanFootballSackAttribute.Assisted,
        AmericanFootballSackAttribute.NotAssisted,
      ],
      cols: 2,
      important: true,
      type: DetailType.Button,
      fulfilledWhen: (attributes, incident) => {
        if (attributes.length === 0) {
          return false
        }

        if (attributes.includes(AmericanFootballSackAttribute.Assisted)) {
          return (
            incident?.participant_id !== null && incident?.assistant_id !== null
          )
        }

        return true
      },
    },
  ],
  [AmericanFootballIncidentId.Pass]: [
    {
      name: AmericanFootballGroupNames.Details,
      attributes: [
        AmericanFootballPassAttribute.PassComplete,
        AmericanFootballPassAttribute.PassIncomplete,
      ],
      cols: 2,
      important: true,
      type: DetailType.Button,
      fulfilledWhen: (attributes, incident) => {
        if (attributes.length === 0) {
          return false
        }
        const attribute = attributes[0]

        switch (attribute) {
          case AmericanFootballPassAttribute.PassComplete: {
            return (
              incident?.participant_id !== null &&
              incident?.properties?.find(
                (x) =>
                  x.short_name.toLowerCase() === 'yards' && x.value !== null,
              ) !== undefined
            )
          }
          case AmericanFootballPassAttribute.PassIncomplete: {
            return true
          }
          default: {
            return false
          }
        }
      },
    },
    {
      name: AmericanFootballGroupNames.Yards,
      important: true,
      type: DetailType.Input,
      fulfilledWhen: (attributes, incident) =>
        attributes.includes(AmericanFootballPassAttribute.PassIncomplete)
          ? true
          : hasYardsPropertyFulfilled(incident),
      placeholder: 'Yards  (-99 - 99)',
      min: -99,
      max: 99,
    },
  ],
  [AmericanFootballIncidentId.Rush]: [
    {
      name: AmericanFootballGroupNames.Yards,
      important: true,
      type: DetailType.Input,
      fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
      placeholder: 'Yards  (-99 - 99)',
      min: -99,
      max: 99,
    },
  ],
  [AmericanFootballIncidentId.Penalty]: [
    {
      name: AmericanFootballGroupNames.Yards,
      important: true,
      type: DetailType.Input,
      fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
      placeholder: 'Yards  (0 - 100)',
      min: 0,
      max: 100,
    },
  ],
  [AmericanFootballIncidentId.Punt]: [
    {
      name: AmericanFootballGroupNames.Yards,
      important: true,
      type: DetailType.Input,
      fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
      placeholder: 'Yards (-99 - 100)',
      min: -99,
      max: 100,
    },
  ],
  [AmericanFootballIncidentId.DefensiveReturn]: [
    {
      name: AmericanFootballGroupNames.Details,
      attributes: [
        AmericanFootballDefensiveReturnAttribute.Fumble,
        AmericanFootballDefensiveReturnAttribute.Interception,
        AmericanFootballDefensiveReturnAttribute.Kickoff,
        AmericanFootballDefensiveReturnAttribute.Punt,
      ],
      cols: 4,
      important: true,
      type: DetailType.Button,
      fulfilledWhen: (attributes) => attributes.length > 0,
    },
    {
      name: AmericanFootballGroupNames.Yards,
      important: true,
      type: DetailType.Input,
      fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
      placeholder: 'Yards (-99 - 99)',
      min: -99,
      max: 99,
    },
  ],
  [AmericanFootballIncidentId.FirstDown]: [
    {
      name: AmericanFootballGroupNames.Yards,
      important: true,
      type: DetailType.Input,
      fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
      placeholder: 'Yards (0 - 100)',
      min: 0,
      max: 100,
    },
  ],
  [AmericanFootballIncidentId.SecondDown]: [
    {
      name: AmericanFootballGroupNames.Yards,
      important: true,
      type: DetailType.Input,
      fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
      placeholder: 'Yards (0 - 100)',
      min: 0,
      max: 100,
    },
  ],
  [AmericanFootballIncidentId.ThirdDown]: [
    {
      name: AmericanFootballGroupNames.Yards,
      important: true,
      type: DetailType.Input,
      fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
      placeholder: 'Yards (0 - 100)',
      min: 0,
      max: 100,
    },
  ],
  [AmericanFootballIncidentId.FourthDown]: [
    {
      name: AmericanFootballGroupNames.Yards,
      important: true,
      type: DetailType.Input,
      fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
      placeholder: 'Yards (0 - 100)',
      min: 0,
      max: 100,
    },
  ],
  [AmericanFootballIncidentId.Possession]: [
    {
      name: AmericanFootballGroupNames.Yards,
      important: true,
      type: DetailType.Input,
      fulfilledWhen: (_, incident) => hasYardsPropertyFulfilled(incident),
      placeholder: 'Yards (0 - 100)',
      min: 0,
      max: 100,
    },
  ],
}

export const AmericanFootballAttributesConfiguration = {
  [AmericanFootballIncidentId.Touchdown]: [
    AmericanFootballTouchdownAttribute.Passing,
    AmericanFootballTouchdownAttribute.Rushing,
    AmericanFootballTouchdownAttribute.InterceptionReturn,
    AmericanFootballTouchdownAttribute.FumbleRecovery,
    AmericanFootballTouchdownAttribute.PuntReturn,
    AmericanFootballTouchdownAttribute.KickoffReturn,
  ],
  [AmericanFootballIncidentId.Sack]: [
    AmericanFootballSackAttribute.Assisted,
    AmericanFootballSackAttribute.NotAssisted,
  ],
  [AmericanFootballIncidentId.DefensiveReturn]: [
    AmericanFootballDefensiveReturnAttribute.Fumble,
    AmericanFootballDefensiveReturnAttribute.Interception,
    AmericanFootballDefensiveReturnAttribute.Kickoff,
    AmericanFootballDefensiveReturnAttribute.Punt,
  ],
  [AmericanFootballIncidentId.Pass]: [
    AmericanFootballPassAttribute.PassComplete,
    AmericanFootballPassAttribute.PassIncomplete,
  ],
}
