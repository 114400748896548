import { computed, shallowReactive } from 'vue'

import {
  IncidentActionType,
  Sport,
  SportId,
} from '@collector/sportsapi-client-legacy'
import { AppDatabase } from '@mobile/database/AppDatabase'
import { CollectorSport } from '@mobile/reusables/classes/CollectorSport'

type SportList = {
  [key in SportId]?: CollectorSport
}

const state = shallowReactive({
  isOnline: navigator.onLine,
  ready: false,
  wasRecentlyUpdated: localStorage.getItem('appWasRecentlyUpdated') === 'true',
  database: null as unknown as AppDatabase,
  isDatabaseConnected: false,
  sports: shallowReactive({} as SportList),
  lastIncidentAction: null as unknown as IncidentActionType,
})

window.addEventListener('online', () => {
  state.isOnline = true
})

window.addEventListener('offline', () => {
  state.isOnline = false
})

const isOnline = computed(() => state.isOnline)

function setAppAsReady(): void {
  state.ready = true
}

function setDatabaseAsConnected(): void {
  state.isDatabaseConnected = true
}

function setDatabaseAsDisconnected(): void {
  state.isDatabaseConnected = false
}

function setAppAsUnready(): void {
  state.ready = false
}

function setDatabase(database: AppDatabase): void {
  state.database = database
}

function getSport(sportId: SportId): CollectorSport | undefined {
  return state.sports[sportId]
}

function putSport(sport: Sport): void {
  state.sports[sport.id] = new CollectorSport(sport)
}

export {
  state,
  isOnline,
  setAppAsReady,
  setAppAsUnready,
  setDatabaseAsConnected,
  setDatabaseAsDisconnected,
  setDatabase,
  getSport,
  putSport,
  SportList,
}
