<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'

import { IncidentActionType } from '@collector/sportsapi-client-legacy'
import HistoryItemTableRow from '@mobile/views/Relation/DetailsPanel/Table/HistoryItemTableRow.vue'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import { useHistoryIncidents } from './useHistoryIncidents'

export default defineComponent({
  components: { HistoryItemTableRow },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
  },
  emits: [
    'openIncidentsDetailModal',
    'openIncidentsDeleteModal',
    'openSwapIncidentModal',
    'pinIncident',
    'click',
  ],
  setup(props) {
    const eventInjections = useEventInjections()
    const { selectIncident, isIncidentSelected } =
      useHistoryIncidents(eventInjections)
    const isDeleted = computed(
      () => props.linkedIncident.incident.action === IncidentActionType.DELETE,
    )
    const position = ref({
      top: 0,
      left: 0,
    })
    const docked = computed(() => isIncidentSelected(props.linkedIncident))

    function handleClick(event: PointerEvent): void {
      if (docked.value) {
        return
      }

      const target = event.currentTarget as HTMLElement

      position.value = {
        top: target.offsetTop,
        left: target.offsetLeft,
      }

      selectIncident(props.linkedIncident)
    }

    return {
      docked,
      isIncidentSelected,
      isDeleted,
      position,
      handleClick,
    }
  },
})
</script>

<template>
  <HistoryItemTableRow
    v-bind="{ linkedIncident, position, docked }"
    @openIncidentsDetailModal="$emit('openIncidentsDetailModal')"
    @openIncidentsDeleteModal="$emit('openIncidentsDeleteModal')"
    @openSwapIncidentModal="$emit('openSwapIncidentModal')"
    @click="handleClick"
  />
  <HistoryItemTableRow
    v-if="isIncidentSelected(linkedIncident)"
    v-bind="{ linkedIncident }"
    @openIncidentsDetailModal="$emit('openIncidentsDetailModal')"
    @openIncidentsDeleteModal="$emit('openIncidentsDeleteModal')"
    @openSwapIncidentModal="$emit('openSwapIncidentModal')"
  />
</template>
