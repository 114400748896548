import { IncidentActionType } from '@collector/sportsapi-client-legacy'
import { getCurrentEvent } from '@mobile/globalState/event'
import * as sportsAPI from '@mobile/globalState/sportsAPI'

import IncidentAction, { ProbableData } from './IncidentAction'
import IncidentId from './IncidentId'
import { isActionId, isIncidentId } from './ProbableIncident'

export default class SwapIncident extends IncidentAction {
  public readonly type = 'SwapIncident'

  constructor(
    public override readonly actionOrIncidentId: string,
    public readonly swapIncidentDTO: {
      incident_id: number
      team_id: number
    },
  ) {
    super()

    if (isIncidentId(actionOrIncidentId)) {
      this.incidentId = actionOrIncidentId
    } else if (isActionId(actionOrIncidentId)) {
      this.dependencies.push(actionOrIncidentId)
    }
  }

  public override applyProbableResult({
    probableIncidents,
  }: ProbableData): void {
    const incident = probableIncidents.find(
      (probableIncident) => probableIncident.id === this.actionOrIncidentId,
    )

    if (incident) {
      incident.action = IncidentActionType.DELETE
    }
  }

  public async execImpl(): Promise<void> {
    const event = getCurrentEvent()

    await sportsAPI.state.client.swapIncident(
      event.id,
      IncidentId.fromPushMessageFormat(this.incidentId!),
      this.swapIncidentDTO.incident_id,
      this.swapIncidentDTO.team_id,
    )
  }
}
