<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  emits: ['close'],
})
</script>

<template>
  <div
    class="bg-modal-wrapper fixed inset-0 z-30 h-screen w-screen overflow-x-hidden overflow-y-scroll"
    @click="$emit('close')"
  >
    <div
      class="absolute left-1/2 top-0 max-h-screen w-full -translate-x-1/2 translate-y-0 transform p-6 lg:top-1/2 lg:w-2/3 lg:-translate-y-1/2 xl:w-1/2"
    >
      <div
        class="bg-silver w-full rounded-md p-5"
        @click.stop
      >
        <slot />
      </div>
    </div>
  </div>
</template>
