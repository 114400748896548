<script lang="ts">
import { computed, defineComponent } from 'vue'

import { ActionQueueStatus } from '@mobile/ActionQueue/ActionQueue'
import { UpdateLineups } from '@mobile/ActionQueue/Actions/Actions'
import UpdateEvent from '@mobile/ActionQueue/Actions/Event/UpdateEvent'
import UpdateEventParticipants from '@mobile/ActionQueue/Actions/Event/UpdateEventParticipants'
import UpdateEventTime from '@mobile/ActionQueue/Actions/Event/UpdateEventTime'
import AddIncident from '@mobile/ActionQueue/Actions/Incidents/AddIncident'
import DeleteIncident from '@mobile/ActionQueue/Actions/Incidents/DeleteIncident'
import SwapIncident from '@mobile/ActionQueue/Actions/Incidents/SwapIncident'
import UpdateIncident from '@mobile/ActionQueue/Actions/Incidents/UpdateIncident'
import Button from '@mobile/components/Button/Button.vue'
import PopupTitle from '@mobile/views/Relation/Shared/Popup/PopupTitle.vue'
import QueueAction from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueAction.vue'
import QueueAddIncident from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueAddIncident.vue'
import QueueDeleteIncident from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueDeleteIncident.vue'
import QueueUpdateEvent from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueUpdateEvent.vue'
import QueueUpdateEventParticipants from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueUpdateEventParticipants.vue'
import QueueUpdateEventTime from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueUpdateEventTime.vue'
import QueueUpdateIncident from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueUpdateIncident.vue'
import QueueUpdateLineups from '@mobile/views/Relation/Shared/Popups/Queue/Actions/QueueUpdateLineups.vue'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'

import QueueSwapIncident from './Actions/QueueSwapIncident.vue'

export enum QueueType {
  AddIncident,
  DeleteIncident,
  UpdateIncident,
  UpdateEvent,
  UpdateEventTime,
  UpdateEventParticipants,
}

export default defineComponent({
  components: {
    PopupTitle,
    Button,
    QueueAddIncident,
    QueueDeleteIncident,
    QueueUpdateEvent,
    QueueUpdateEventParticipants,
    QueueUpdateIncident,
    QueueAction,
  },
  emits: ['close'],
  setup() {
    const { actionQueue } = useEventInjections()

    const queueItemsMap = [
      {
        component: QueueAddIncident,
        queueActionType: AddIncident,
      },
      {
        component: QueueDeleteIncident,
        queueActionType: DeleteIncident,
      },
      {
        component: QueueUpdateIncident,
        queueActionType: UpdateIncident,
      },
      {
        component: QueueUpdateEvent,
        queueActionType: UpdateEvent,
      },
      {
        component: QueueUpdateEventParticipants,
        queueActionType: UpdateEventParticipants,
      },
      {
        component: QueueUpdateEventTime,
        queueActionType: UpdateEventTime,
      },
      {
        component: QueueUpdateLineups,
        queueActionType: UpdateLineups,
      },
      {
        component: QueueSwapIncident,
        queueActionType: SwapIncident,
      },
    ] as const

    const queueItems = computed(() => {
      return actionQueue.value.queue.flatMap((action) => {
        const queueItem = queueItemsMap.find(
          (queueItem) => action instanceof queueItem.queueActionType,
        )

        if (queueItem) {
          return {
            component: queueItem.component,
            action,
          }
        }
        /* eslint-disable-next-line no-console */
        console.warn(
          `Queue found action that is not supported: ${JSON.stringify(action)}`,
        )

        return []
      })
    })
    const isQueueWorking = computed(
      () => actionQueue.value.status === ActionQueueStatus.Working,
    )

    function toggleQueue(): void {
      actionQueue.value.toggle()
    }

    const queueErrored = computed(
      () => !!actionQueue.value.currentAction?.error,
    )

    return {
      QueueType,
      queueItemsMap,
      actionQueue,
      toggleQueue,
      isQueueWorking,
      queueItems,
      queueErrored,
      ActionQueueStatus,
    }
  },
})
</script>

<template>
  <div>
    <PopupTitle>
      <template #title>
        Queue&nbsp;
        <span
          v-if="actionQueue.status === ActionQueueStatus.Working"
          class="text-green"
        >
          is working
        </span>
        <span
          v-else-if="actionQueue.status === ActionQueueStatus.Pausing"
          class="text-yellow"
        >
          pausing...
        </span>
        <span
          v-else-if="actionQueue.status === ActionQueueStatus.Paused"
          class="text-yellow"
        >
          paused
        </span>
      </template>

      <template #controls>
        <div class="flex h-10 max-h-10 items-center justify-end">
          <Button
            class="w-16"
            :size="'sm'"
            :color="isQueueWorking ? 'yellow' : 'green'"
            :disabled="
              queueErrored || actionQueue.status === ActionQueueStatus.Pausing
            "
            @click="toggleQueue()"
          >
            <FontAwesomeIcon
              v-if="isQueueWorking"
              :icon="'pause'"
            />
            <FontAwesomeIcon
              v-else
              :icon="'play'"
            />
          </Button>
        </div>
      </template>
    </PopupTitle>
  </div>
  <div class="space-y-1 px-3 pb-3">
    <component
      :is="queueItem.component"
      v-for="queueItem in queueItems"
      :key="queueItem.action.id"
      :action="queueItem.action as any"
    />
  </div>
</template>
