import {
  BasketballAttributeId,
  BasketballEventStatusId,
  BasketballIncidentId,
} from '@collector/sportsapi-client-legacy'

import { DetailsPanelSportConfiguration } from '../DetailsPanelSportConfigurationFactory'
import {
  BasketballAttributesConfiguration,
  BasketballDetailsConfiguration,
  BasketballGroupNames,
} from './BasketballDetailsConfiguration'
import {
  BasketballDetailsIncidentIds,
  BasketballHideIncidentIds,
  BasketballParticipantIncidentIds,
  BasketballSecondParticipantIncidentIds,
} from './BasketballFiltersIncidentIds'
import { BasketballManualAddIncidentIds } from './BasketballManualAddIncidentIds'
import { BasketballManualAddStatusIds } from './BasketballManualAddStatusIds'

export const BasketballConfiguration: DetailsPanelSportConfiguration<
  BasketballIncidentId,
  BasketballAttributeId,
  BasketballEventStatusId
> = {
  groupNames: BasketballGroupNames,
  detailsConfiguration: BasketballDetailsConfiguration,
  attributesConfiguration: BasketballAttributesConfiguration,
  secondParticipantIncidents: BasketballSecondParticipantIncidentIds,
  filters: {
    hideIncidents: BasketballHideIncidentIds,
    participantIncidents: BasketballParticipantIncidentIds,
    detailsIncidents: BasketballDetailsIncidentIds,
    selectedOnStartDetailsIncidents: BasketballDetailsIncidentIds,
  },
  manualAddIncidentModal: {
    statuses: BasketballManualAddStatusIds,
    incidents: BasketballManualAddIncidentIds,
    substitutionIncidents: [],
  },
}
