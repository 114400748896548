<script setup lang="ts">
import { get } from 'lodash'
import { computed, ref, Ref } from 'vue'

import { IncidentsQueuePatchDTO } from '@collector/sportsapi-client-legacy'
import UpdateIncident from '@mobile/ActionQueue/Actions/Incidents/UpdateIncident'
import { useLineupsSelectOptions } from '@mobile/views/Relation/Shared/Lineups/useLineupsSelectOption'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import { useSportConfiguration } from '@mobile/views/Relation/Shared/RelationSportCommonDependencies/useSportConfiguration'

import { getFilteredSelectOptions } from '../partcipantsSearchFilter'
import Select from './Select.vue'
import { PlayerSelectUpdatePayload } from './types'
import { isSelectFulfilled } from './utils'

interface Props {
  linkedIncident: ProbableLinkedIncident
  openedList: boolean
  disabled: boolean
  time: Pick<IncidentsQueuePatchDTO, 'minute' | 'second'>
}

const props = defineProps<Props>()

// composables
const { actionQueue, probableEvent } = useEventInjections()
const { assignableIncidentsConfiguration } = useSportConfiguration()
const assignableIncidentConfiguration = get(
  assignableIncidentsConfiguration,
  props.linkedIncident.incident.incident_id,
  null,
)
const {
  selectedSecondaryParticipant,
  secondaryLineupsParticipants,
  findParticipant,
} = useLineupsSelectOptions(
  computed(() => props.linkedIncident.incident),
  props.linkedIncident.side ?? undefined,
  assignableIncidentConfiguration,
)

// data
const event_id = probableEvent.value?.id

// state
const secondaryParticipantSearchValue: Ref<string> = ref('')

// computed
const filteredSecondarySelectOptions = computed(() =>
  getFilteredSelectOptions(
    secondaryLineupsParticipants.value,
    secondaryParticipantSearchValue.value,
  ),
)

const valid = computed(() =>
  isSelectFulfilled('secondary', 'assistant_id', props.linkedIncident),
)

// methods
function updateIncident(payload: PlayerSelectUpdatePayload): void {
  actionQueue.value.add(
    new UpdateIncident(props.linkedIncident.incident.id, {
      incident_id: props.linkedIncident.incident.incident_id,
      event_id,
      status_id: props.linkedIncident.incident.event_status_id,
      attribute_ids: props.linkedIncident.incident.attribute_ids,
      participant_team_id: props.linkedIncident.incident.participant_id,
      minute: props.time.minute,
      second: props.time.second,
      // TODO: remove this line when Team API will fix update payloads
      properties: props.linkedIncident.incident.properties,
      ...payload,
    }),
  )
}

function onSecondaryParticipantUpdate(participantId: number | null): void {
  updateIncident({
    participant_id: props.linkedIncident.incident.subparticipant_id,
    participant_name: props.linkedIncident.incident.subparticipant_name,
    subparticipant_id: props.linkedIncident.incident.subparticipant_id,
    subparticipant_name: props.linkedIncident.incident.subparticipant_name,
    assistant_id: participantId,
    assistant_name: findParticipant(participantId)?.name,
    secondary_assistant_id:
      props.linkedIncident.incident.secondary_assistant_id,
    secondary_assistant_name:
      props.linkedIncident.incident.secondary_assistant_name,
  })
}
</script>

<template>
  <Select
    v-model="selectedSecondaryParticipant"
    participantType="secondary"
    :linkedIncident
    :valid
    :openedList
    :disabled
    :options="filteredSecondarySelectOptions"
    @searchChange="secondaryParticipantSearchValue = $event"
    @update="onSecondaryParticipantUpdate"
  />
</template>
