<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { masterAttributes } from '@collector/shared-ui-domain'
import { opChain } from '@collector/shared-utils'
import { IncidentId, Property } from '@collector/sportsapi-client-legacy'
import { useSportOptions } from '@mobile/views/Relation/DetailsPanel/Sports/composables/useSportOptions'
import { useEventRelationSettings } from '@mobile/views/Relation/DetailsPanel/Sports/useEventRelationSettings'
import { ProbableLinkedIncident } from '@mobile/views/Relation/Shared/RelationDependencies/types'
import { useEventInjections } from '@mobile/views/Relation/Shared/RelationDependencies/useEventInjections'
import RelationIcon, {
  RelationIconType,
} from '@mobile/views/Relation/Shared/RelationIcon/RelationIcon.vue'

export default defineComponent({
  components: { RelationIcon },
  props: {
    linkedIncident: {
      type: Object as PropType<ProbableLinkedIncident>,
      required: true,
    },
    participantsIncidents: {
      type: Array as PropType<IncidentId[]>,
      default: () => [],
    },
    detailsIncidents: {
      type: Array as PropType<IncidentId[]>,
      default: () => [],
    },
    showParticipants: {
      type: Boolean,
      required: true,
      default: false,
    },
    showAttributes: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const eventInjections = useEventInjections()
    const { sport } = eventInjections
    const sportOptionsComposable = useSportOptions(sport)
    const { attributesConfiguration } = sportOptionsComposable
    const { participantsIncidents, detailsIncidents } =
      useEventRelationSettings(eventInjections, sportOptionsComposable)

    function getPropertiesValue(properties: Property[]): string {
      return properties.map((property) => property.value).join(', ')
    }

    function getAttributesNames(attributeIds: number[]): string {
      const attributesToShow = [
        ...(attributesConfiguration[
          props.linkedIncident.incident.incident_id
        ] || []),
        ...masterAttributes,
      ]

      const filteredAttributes =
        props.linkedIncident.sportIncident.attributes.filter(
          (attribute) =>
            attributeIds.includes(attribute.id) &&
            attributesToShow.includes(attribute.id),
        )

      return filteredAttributes.map((attribute) => attribute.name).join(', ')
    }

    const boldIncidentName = computed(
      () =>
        participantsIncidents.value.includes(
          props.linkedIncident.incident.incident_id,
        ) ||
        detailsIncidents.value.includes(
          props.linkedIncident.incident.incident_id,
        ),
    )

    return {
      getAttributesNames,
      RelationIconType,
      boldIncidentName,
      getPropertiesValue,
      opChain,
    }
  },
})
</script>

<template>
  <div class="flex items-center">
    <RelationIcon
      class="mr-2"
      :size="18"
      :iconId="linkedIncident.incident.incident_id"
      :type="RelationIconType.Incidents"
    />
    <div class="flex flex-col">
      <span
        v-if="linkedIncident.incident.participant_name"
        class="text-xxs font-bold"
        :class="
          opChain(
            linkedIncident.probableLinkedParticipant,
            (v) => v.css.textClass,
          )
        "
      >
        {{ linkedIncident.incident.participant_name }}
      </span>
      <div
        class="text-base"
        :class="{ 'font-bold': boldIncidentName }"
      >
        <span>{{ linkedIncident.incident.incident_name }}</span>

        <span
          v-if="
            getAttributesNames(linkedIncident.incident.attribute_ids).length &&
            showAttributes
          "
          class="font-normal"
        >
          ({{ getAttributesNames(linkedIncident.incident.attribute_ids) }})
          <span
            v-if="
              linkedIncident.incident.properties &&
              getPropertiesValue(linkedIncident.incident.properties).length
            "
          >
            ,
          </span>
        </span>
        <span
          v-if="
            linkedIncident.incident.properties &&
            opChain(linkedIncident.incident.properties, (v) => v.length)
          "
          class="pl-1 font-normal"
        >
          {{ getPropertiesValue(linkedIncident.incident.properties) }}
        </span>
      </div>
      <div
        v-if="linkedIncident.incident.subparticipant_name"
        class="text-xxs font-bold"
      >
        <span>{{ linkedIncident.incident.subparticipant_name }}</span>
        <span
          v-if="
            opChain(
              linkedIncident.incident.additional_info,
              (v) => v.assistant_id,
            )
          "
          class="ml-1"
        >
          ({{
            opChain(
              linkedIncident.incident.additional_info,
              (v) => v.assistant_name,
            )
          }})
        </span>
      </div>
    </div>
  </div>
</template>
