<script setup lang="ts">
import { computed, reactive } from 'vue'

import { Participant } from '@collector/sportsapi-client-legacy'
import SwapIncident from '@mobile/ActionQueue/Actions/Incidents/SwapIncident'
import Button from '@mobile/components/Button/Button.vue'
import ParticipantLogo from '@mobile/components/ParticipantLogo/ParticipantLogo.vue'
import { isAwayTeam, isHomeTeam } from '@mobile/reusables/entityUtils'

import { ProbableLinkedIncident } from '../../../Shared/RelationDependencies/types'
import { useEventInjections } from '../../../Shared/RelationDependencies/useEventInjections'
import { useSwappableIncidents } from '../../../useSwappableIncidents'
import { useHistoryIncidents } from '../../Table/useHistoryIncidents'
import { formatDate } from '../../utils'
import Modal from '../Modal.vue'
import ModalButtons from '../ModalButtons.vue'
import ModalTitle from '../ModalTitle.vue'
import SwapIncidentButton from './SwapIncidentButton.vue'

interface Props {
  linkedIncident: ProbableLinkedIncident
}

const props = defineProps<Props>()

// emits
const emit = defineEmits(['close'])

// composables
const { getConfigForIncident } = useSwappableIncidents()
const eventInjections = useEventInjections()
const { probableHomeParticipant, probableAwayParticipant, actionQueue } =
  useEventInjections()
const { clearSelection } = useHistoryIncidents(eventInjections)

// data
const incidentParticipant =
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  props.linkedIncident.probableLinkedParticipant?.getParticipant().value!
const [firstParticipant, secondParticipant] =
  incidentParticipant.id === probableHomeParticipant.value.id
    ? [probableHomeParticipant.value, probableAwayParticipant.value]
    : [probableAwayParticipant.value, probableHomeParticipant.value]
const config = getConfigForIncident(props.linkedIncident.incident.incident_id)!

// state
const payload = reactive({
  incident_id: 0,
  team_id: 0,
})

// computed
const isFormValid = computed(
  () => payload.incident_id !== 0 && payload.team_id !== 0,
)

// methods
function swapIncident(): void {
  actionQueue.value.add(
    new SwapIncident(props.linkedIncident.incident.id, payload),
  )

  clearSelection()
  emit('close')
}

function selectIncident(incidentId: number, participant: Participant): void {
  payload.incident_id = incidentId
  payload.team_id = participant.id
}
</script>

<template>
  <Modal
    id="swapIncidentModal"
    @close="$emit('close')"
  >
    <ModalTitle title="Replace incident" />

    <div class="pb-2 text-center font-bold">Incident to replace</div>
    <div
      class="flex space-x-8 rounded-md px-4 py-3"
      :class="{
        'bg-gradient-to-r-home-32': props.linkedIncident.side === 'home',
        'bg-gradient-to-r-away-32': props.linkedIncident.side === 'away',
      }"
    >
      <div>{{ formatDate(props.linkedIncident.incident.ut) }}</div>
      <div class="flex space-x-2">
        <div>{{ props.linkedIncident.sportStatus.short_name }}</div>
        <div class="font-bold">
          {{ props.linkedIncident.incident.event_time }}
        </div>
      </div>
      <div class="flex space-x-2 font-bold">
        <div
          :class="{
            'text-home': isHomeTeam(incidentParticipant),
            'text-away': isAwayTeam(incidentParticipant),
          }"
        >
          {{ incidentParticipant.short_name }}
        </div>
        <div>{{ props.linkedIncident.sportIncident.name }}</div>
      </div>
    </div>

    <div class="py-4 text-center font-bold">Replace to</div>

    <div class="flex space-x-4">
      <template
        v-for="(participant, index) in [firstParticipant, secondParticipant]"
        :key="`swap-incident-participant-${index}`"
      >
        <div class="w-1/2">
          <div
            class="flex items-center justify-center space-x-2 pb-4 font-bold"
            :class="{
              'text-home': isHomeTeam(participant),
              'text-away': isAwayTeam(participant),
            }"
          >
            <ParticipantLogo
              class="h-auto w-8"
              :participant
            />
            <div class="text-center">{{ participant.short_name }}</div>
          </div>

          <div class="grid grid-flow-col gap-2">
            <SwapIncidentButton
              v-for="incidentId in index === 0
                ? config.sameTeam
                : config.rivalTeam"
              :key="`swap-incident-${participant.counter}-button-${incidentId}`"
              :selected="
                payload.incident_id == incidentId &&
                payload.team_id == participant.id
              "
              :participant
              :incidentId
              @click="selectIncident(incidentId, participant)"
            />
          </div>
        </div>
      </template>
    </div>

    <ModalButtons>
      <Button
        color="red"
        size="lg"
        @click="$emit('close')"
      >
        Close
      </Button>
      <Button
        color="green"
        size="lg"
        :disabled="!isFormValid"
        @click="swapIncident"
      >
        Replace
      </Button>
    </ModalButtons>
  </Modal>
</template>
