<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<template>
  <div class="bg-gray/30 table w-full border-separate border-spacing-y-[1.5px]">
    <div class="table-header-group">
      <div class="bg-lightgray table-row font-bold">
        <div class="table-cell p-2"></div>
        <div class="table-cell p-2">Time</div>
        <div class="hidden p-2 xl:table-cell">Status</div>
        <div class="table-cell p-2">Event time</div>
        <div class="table-cell p-2"></div>
        <div class="table-cell p-2">Incident</div>
        <div class="table-cell py-2">Actions</div>
      </div>
    </div>
    <div class="relative table-row-group bg-white">
      <slot></slot>
    </div>
  </div>
</template>
